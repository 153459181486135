var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:{
    'checkbox-right': _vm.checkboxPosition === 'right',
    'font-size-small': _vm.fontSize === 'small'
  },style:({ color: _vm.labelColor })},[(_vm.icon)?_c('Icon',{staticClass:"icon",attrs:{"type":_vm.icon}}):_vm._e(),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]),_vm._t("default"),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValue),expression:"selectedValue"}],attrs:{"type":"radio","name":_vm.name,"required":_vm.required,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm._q(_vm.selectedValue,_vm.value)},on:{"change":function($event){_vm.selectedValue=_vm.value}}}),_c('div',{class:{
      'checkbox-size-small': _vm.checkboxSize === 'small',
      indicator: _vm.indicator,
      'date-list-checkbox': _vm.dateListCheckbox === true,
      'date-list-label': _vm.dateListLabel
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }