<template>
  <div class="has-tab-bar">
    <AppBar
      :title="group && league ? league.name : 'Niet gevonden'"
      :show-back-button="true"
    />
    <SpinnerLoader v-if="loading" />
    <div v-else class="container">
      <template v-if="!group">
        <h1 class="page-title mt-4 mb-5">Oeps</h1>
        <p>We kunnen deze groep helaas niet vinden</p>
      </template>
      <template v-else-if="!group">
        <h1 class="page-title mt-4 mb-5">Oeps</h1>
        <p>We kunnen deze competitie helaas niet vinden</p>
      </template>
      <template v-else>
        <h1 class="page-title mt-4 mb-5">
          {{ league.name }}
        </h1>
        <TabNavigation
          :tabs="tabs"
          :active-tab="activeTab"
          @toggle="(key) => (activeTab = key)"
        />
        <template v-if="activeTab === 'ranking'">
          <div v-if="teamRanking">
            <GroupListItem
              v-for="(row, rank) in teamRanking"
              :id="parseInt(row.id)"
              :key="parseInt(row.id)"
              :name="row.name"
              :text-left="(rank + 1).toString()"
              :text-right="row.points.toString()"
              :show-arrow="false"
              :show-pointer="false"
            />
          </div>
        </template>
        <template v-if="activeTab === 'matches'">
          <div v-if="teamMatches">
            <GroupListItem
              v-for="(row, rank) in teamMatches"
              :id="parseInt(row.id)"
              :key="parseInt(row.id)"
              :name="`${row.team} (${row.id})`"
              :text-left="(rank + 1).toString()"
              :text-right="row.points.toString()"
              :show-arrow="false"
              :show-pointer="false"
            />
          </div>
        </template>
      </template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import GroupListItem from '@/components/GroupListItem'
import TabNavigation from '@/components/TabNavigation'
import { mapState } from 'vuex'
import { LEAGUE_GET_DETAILS, LEAGUE_GET_RESULTS } from '@/store/actions/league'
import { GROUP_GET_DETAILS } from '@/store/actions/group'

export default {
  name: 'GroupsLeagueDetailPage',
  components: { AppBar, SpinnerLoader, TabBar, GroupListItem, TabNavigation },
  data() {
    return {
      groupId: this.$route.params.id,
      leagueId: this.$route.params.leagueId,
      tabs: [
        {
          key: 'ranking',
          value: 'Ranking'
        },
        {
          key: 'matches',
          value: 'Wedstrijden en uitslagen',
          disabled: true
        }
      ],
      activeTab: 'ranking'
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.league.loading || state.group.loading,
      group: (state) => state.group.groupDetails,
      league: (state) => state.league.leagueDetails,
      teamRanking: (state) => state.league.ranking,
      teamMatches: (state) => state.league.matches
    })
  },
  created: function () {
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    this.$store.dispatch(LEAGUE_GET_DETAILS, this.leagueId)
    this.$store.dispatch(LEAGUE_GET_RESULTS, this.leagueId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.table {
  display: flex;
  margin-top: 2rem;
}
.rank {
  width: 2rem;
}
.team {
  flex: 1;
}
.points {
  width: 2rem;
  font-weight: bold;
}
</style>
