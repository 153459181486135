var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-tab-bar"},[_c('AppBar',{attrs:{"title":_vm.group && _vm.group.name,"show-back-button":true}}),(_vm.loading)?_c('SpinnerLoader'):_vm._e(),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"page-title mt-4 mb-3"},[_vm._v("Alle groepsleden")]),(
        _vm.group &&
        _vm.group.members &&
        _vm.group.members.some(function (member) { return member.status === 'accepted'; })
      )?_vm._l((_vm.group.members.filter(
          function (member) { return member.status === 'accepted'; }
        )),function(member){return _c('GroupListItem',{key:member.id,staticClass:"mb-2",attrs:{"id":member.id,"name":("" + (member.name) + (_vm.isOwner(member.participantId) ? ' (beheerder)' : '')),"first-name":member.firstName,"last-name":member.lastName,"image-url":member.avatarUrl,"show-arrow":false,"show-pointer":false}})}):[_vm._v("Geen leden gevonden")],(
        _vm.group &&
        _vm.group.members &&
        _vm.group.members.some(function (member) { return member.status === 'pending'; }) &&
        _vm.participantIsOwner
      )?[_c('h1',{staticClass:"page-title mt-4 mb-3"},[_vm._v("Leden in afwachting")]),_vm._l((_vm.group.members.filter(
          function (member) { return member.status === 'pending'; }
        )),function(member){return _c('GroupListItem',{key:member.id,attrs:{"id":member.id,"name":("" + (member.name)),"image-url":member.avatarUrl,"show-arrow":false,"show-pointer":false,"button-text":"voeg toe","second-button-text":"weiger"},on:{"button-action":_vm.addMember,"second-button-action":_vm.rejectMember}})})]:_vm._e()],2),_c('TabBar',{attrs:{"selected":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }