<template>
  <div class="has-tab-bar">
    <AppBar
      :title="teamId ? 'Team aanpassen' : 'Nieuw team maken'"
      :show-back-button="true"
    />
    <div class="container">
      <SpinnerLoader v-if="loading" />
      <template v-if="routeName === 'GroupsTeamEdit' && !teamBelongsToGroup">
        <p class="mt-5">Dit team hoort niet bij deze groep.</p>
        <a href="#" class="link" @click.prevent="routeToTeamsOverviewPage">
          Ga terug naar het teamoverzicht
        </a>
      </template>
      <template
        v-else-if="routeName === 'GroupsTeamEdit' && !isOwner && !isLeader"
      >
        <p class="mt-5">Je hebt geen rechten om deze groep aan te passen.</p>
        <a href="#" class="link" @click.prevent="routeToTeam">
          Ga terug naar teampagina
        </a>
      </template>
      <template v-else-if="createdTeam">
        <h1 class="page-title mt-4">Gelukt!</h1>
        <p class="mt-5">
          Je hebt het team
          <strong>{{ createdTeam.name }}</strong>
          aangemaakt en kunt nu meedoen met competities in je groep.
        </p>
        <div class="mt-5">
          <a
            href="#"
            class="btn btn-green btn-block btn-round"
            @click.prevent="routeToTeam(createdTeam.id)"
          >
            bekijk je team
          </a>
        </div>
      </template>
      <template v-else-if="updatedTeam">
        <h1 class="page-title mt-4">Gelukt!</h1>
        <p class="mt-5">
          Je hebt het team
          <strong>{{ updatedTeam.name }}</strong>
          bijgewerkt.
        </p>
        <div class="mt-5">
          <a
            href="#"
            class="btn btn-green btn-block btn-round"
            @click.prevent="routeToTeam(updatedTeam.id)"
          >
            bekijk je team
          </a>
        </div>
      </template>
      <template v-else-if="team">
        <GroupTeamCreateOrEditForm
          :team="team"
          @submit-team="submitTeam"
          @remove-file="onFileRemove"
          @change-file="onFileChange"
        />
      </template>
      <template v-else>Fout: team niet gevonden!</template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState, mapGetters } from 'vuex'
import {
  TEAM_GET_DETAILS,
  TEAM_CREATE,
  TEAM_UPDATE,
  TEAM_RESET_STATE
} from '@/store/actions/team'
import GroupTeamCreateOrEditForm from '@/components/GroupTeamCreateOrEditForm'
import { getOrientation } from '@/utils/getOrientation'
import { GROUP_GET_DETAILS } from '@/store/actions/group'

export default {
  name: 'GroupsTeamCreateOrEdit',
  components: { AppBar, SpinnerLoader, TabBar, GroupTeamCreateOrEditForm },
  data() {
    return {
      groupId: this.$route.params.id,
      teamId: this.$route.params.teamId,
      team: {
        default: () => ({
          image: null,
          imageUrl: null,
          name: null
        })
      },
      imageOrientation: null,
      routeName: this.$route.name
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      loading: (state) =>
        state.group.loading || state.league.loading || state.team.loading,
      teamDetails: (state) => state.team.teamDetails,
      groupDetails: (state) => state.group.groupDetails,
      createdTeam: (state) => state.team.createdTeam,
      updatedTeam: (state) => state.team.updatedTeam,
      teamMembers: (state) => state.team.teamMembers
    }),
    isOwner: function () {
      return (
        this.teamDetails && this.teamDetails.ownerId === parseInt(this.authId)
      )
    },
    isLeader: function () {
      return (
        this.groupDetails &&
        this.groupDetails.admins.includes(parseInt(this.authId))
      )
    },
    teamBelongsToGroup: function () {
      return this.teamDetails.groupId === parseInt(this.groupId)
    }
  },
  watch: {
    teamDetails(teamDetails) {
      this.team = { ...teamDetails }
    }
  },
  created: function () {
    this.$store.commit(TEAM_RESET_STATE)
    if (this.teamId) {
      this.$store.dispatch(TEAM_GET_DETAILS, this.teamId)
      this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    }
  },
  methods: {
    routeToTeam: function (id) {
      this.$router.push(`/groups/${this.groupId}/team/${id}`)
    },
    routeToTeamsOverviewPage: function () {
      this.$router.push(`/groups/${this.groupId}/teams`)
    },
    submitTeam: function (team) {
      if (this.teamId) {
        this.$store.dispatch(TEAM_UPDATE, {
          teamId: this.teamId,
          team
        })
      } else {
        this.$store.dispatch(TEAM_CREATE, {
          groupId: this.groupId,
          team
        })
      }
    },
    onFileChange: function (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.team.image = files[0]
      const vm = this
      getOrientation(files[0], function (orientation) {
        switch (orientation) {
          case 3:
            vm.imageOrientation = 'orientation-3'
            break
          case 6:
            vm.imageOrientation = 'orientation-6'
            break
          case 8:
            vm.imageOrientation = 'orientation-8'
            break
        }
      })
      // Set image url in view
      const reader = new FileReader()
      reader.onload = (e) => {
        vm.team.imageUrl = e.target.result
      }
      reader.readAsDataURL(files[0])
    },
    onFileRemove: function () {
      this.team.image = null
      this.team.imageUrl = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
