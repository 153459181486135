<template>
  <div class="screen-group-detail-page has-tab-bar">
    <AppBar title="Zoeken" :show-back-button="true" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <template v-if="!searchResults.count">
        <h1 class="page-title">Zoek een groep</h1>
        <form @submit.prevent="search">
          <div class="form-group">
            <input
              v-model="searchObj.name"
              type="text"
              class="form-control"
              placeholder="Zoek op groepsnaam"
            />
          </div>
          <div class="form-group">
            <input
              v-model="searchObj.city"
              type="text"
              class="form-control"
              placeholder="Zoek op stad"
            />
          </div>
          <div class="form-group">
            <FormSelect
              v-model="searchObj.activityId"
              :items="activityTypes"
              empty-text="Kies een sport"
              :border-size="2"
            />
          </div>
          <div class="mt-5">
            <button type="submit" class="btn btn-green btn-block btn-round">
              zoeken
            </button>
          </div>
        </form>
        <template v-if="searchResults.count === 0">
          <div class="mt-5 error">
            Er zijn geen groepen gevonden, probeer een andere zoekopdracht
          </div>
        </template>
      </template>
      <template v-else>
        <h1 class="page-title">Gevonden groepen ({{ searchResults.count }})</h1>
        <div class="d-flex flex-column">
          <GroupItem
            v-for="group in searchResults.results"
            :key="group.id"
            view="list"
            :name="group.name"
            :city="group.city"
            :member-count="group.acceptedMembers"
            :image-url="group.imageUrl"
            @click.native="routeToGroupDetailPage(group.id)"
          />
        </div>
        <a
          href="#"
          class="btn btn-green btn-block btn-round mt-5"
          @click.prevent="clearSearchResults"
        >
          Terug naar zoeken
        </a>
      </template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import GroupItem from '@/components/GroupItem'
import FormSelect from '@/components/FormSelect'
import { mapState } from 'vuex'
import { GROUP_SEARCH, GROUP_SEARCH_CLEAR } from '@/store/actions/group'
import { ACTIVITY_LIST } from '@/store/actions/activity'

export default {
  name: 'GroupsSearch',
  components: { AppBar, TabBar, FormSelect, SpinnerLoader, GroupItem },
  data() {
    return {
      searchObj: {
        name: null,
        city: null,
        activityId: null
      },
      activityTypes: []
    }
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityList,
      loading: (state) => state.group.loading,
      searchResults: (state) => state.group.search
    })
  },
  watch: {
    activityList(list) {
      this.activityTypes = list.map((item) => ({
        key: item.id.toString(),
        value: item.name
      }))
    }
  },
  created: function () {
    this.$store.dispatch(ACTIVITY_LIST)
    this.$store.commit(GROUP_SEARCH_CLEAR)
  },
  mounted: function () {
    this.$store.commit(GROUP_SEARCH_CLEAR)
  },
  methods: {
    search: function () {
      this.$store.dispatch(GROUP_SEARCH, this.searchObj)
    },
    clearSearchResults: function () {
      this.$store.commit(GROUP_SEARCH_CLEAR)
    },
    routeToGroupDetailPage: function (groupId) {
      this.$router.push(`/groups/${groupId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.page-title {
  margin: 2rem 0;
}
.error {
  color: $error;
}
.container-groups {
  display: flex;
}
.container-groups-list {
  flex-direction: column;
}
</style>
