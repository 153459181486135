<template>
  <div>
    <AppBar
      title=""
      :show-back-button="true"
      :show-profile-button="false"
      :back-button-path="`/groups/${groupId}/credit`"
    ></AppBar>
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div v-else-if="creditcard" class="container">
      <h1>{{ creditcard.name }}</h1>
      <div>
        <h2>Gekocht door</h2>
        <div
          v-for="(participant, index) in creditcard.purchasedBy"
          :key="index"
          class="flex items-center mt-4 mb-8"
        >
          <initials-avatar
            :size="10"
            :first-name="participant.firstName"
            :last-name="participant.lastName"
          />
          <div class="flex-1 flex-row">
            <div class="flex items-center">
              <h2 class="flex-1 my-0">
                {{ participant.firstName }} {{ participant.lastName }}
              </h2>
              <span class="text-sm">
                {{ participant.used }} / {{ creditcard.timesValid }} gebruikt
              </span>
            </div>
            <div class="text-sm">
              <div class="text-sm text-gray-400">
                Gekocht op {{ participant.purchasedAt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else><h1>Niet gevonden</h1></div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState, mapGetters } from 'vuex'
import config from '@/utils/config'
import { CREDITCARD_GET_ONE } from '@/store/actions/creditcard'
// import IconBase from '@/components/IconBase.vue'
// import IconActivity from '@/components/icons/IconActivity.vue'
// import IconChevronRight from '@/components/icons/IconChevronRight.vue'
import InitialsAvatar from '@/components/InitialsAvatar'

export default {
  name: 'GroupsCreditcardDetails',
  components: {
    AppBar,
    SpinnerLoader,
    InitialsAvatar
    // IconBase,
    // IconChevronRight,
    // IconActivity
  },
  beforeRouteEnter(to, from, next) {
    if (!config.featureFlags.creditcards) {
      next({ name: 'GroupsDetailPage', params: { id: to.params.id } })
    } else {
      next()
    }
  },
  data() {
    return {
      groupId: this.$route.params.id,
      creditcardId: this.$route.params.creditcardId,
      title: null
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.creditcard.loading,
      creditcard: (state) => state.creditcard.creditcard
    }),
    ...mapGetters(['authId'])
  },
  watch: {},
  created: function () {
    this.$store.dispatch(CREDITCARD_GET_ONE, {
      creditcardId: this.creditcardId
    })
  },
  methods: {}
}
</script>
