<template>
  <form @submit.prevent="createGroup">
    <div class="group-image-wrapper">
      <template v-if="group.imageUrl">
        <div
          v-if="group.imageUrl"
          class="image-background"
          :style="{
            'background-image': 'url(' + group.imageUrl + ')'
          }"
        />
        <div class="remove-image" @click="removeFile">X</div>
      </template>
      <template v-else>
        <div class="group-no-image">
          <input
            id="group-image"
            type="file"
            class="input-file"
            accept="image/*"
            @change="changeFile"
          />
          <label for="group-image" class="btn btn-transparent-grey">
            Kies een groepsafbeelding
          </label>
        </div>
      </template>
    </div>
    <div class="form-group">
      <input
        v-model="group.name"
        type="text"
        class="form-control"
        placeholder="Wat is de naam van de groep?"
        required
      />
    </div>
    <div class="form-group">
      <input
        v-model="group.city"
        type="text"
        class="form-control"
        placeholder="In welke stad?"
      />
    </div>
    <div class="form-group">
      <textarea
        v-model="group.description"
        rows="3"
        placeholder="Beschrijf jullie groep"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <FormSelect
        v-model="group.activityId"
        :items="activityTypes"
        empty-text="Kies een sport"
        :border-size="2"
        :required="true"
      />
    </div>
    <div class="form-group">
      <FormSelect
        v-model="group.eventTypeId"
        :items="eventTypes"
        empty-text="Type groep"
        :border-size="2"
        :required="true"
      />
    </div>
    <div class="form-group">
      <FormRadioButtonCheckbox
        :name="group.visibility"
        value="public"
        :selected="group.visibility"
        checkbox-position="left"
        @change="(value) => (group.visibility = value)"
      >
        Dit is een openbare groep
      </FormRadioButtonCheckbox>
    </div>
    <div class="form-group">
      <FormRadioButtonCheckbox
        name="visibility"
        value="needsPermissionToAdd"
        :selected="group.visibility"
        checkbox-position="left"
        @change="(value) => (group.visibility = value)"
      >
        Dit is een besloten groep
      </FormRadioButtonCheckbox>
    </div>
    <div class="form-group">
      <FormRadioButtonCheckbox
        name="visibility"
        value="privateGroup"
        :selected="group.visibility"
        checkbox-position="left"
        @change="(value) => (group.visibility = value)"
      >
        Dit is een privé groep
      </FormRadioButtonCheckbox>
      <div v-if="group.visibility === 'public'" class="alert alert-invi mt-4">
        Iedereen kan jouw groep vinden en zich aanmelden als nieuw lid
      </div>
      <div
        v-if="group.visibility === 'needsPermissionToAdd'"
        class="alert alert-invi mt-4"
      >
        Jouw groep is wel zichtbaar op Inviplay, maar jij moet als beheerder
        nieuwe leden eerst toegang geven
      </div>
      <div
        v-if="group.visibility === 'privateGroup'"
        class="alert alert-invi mt-4"
      >
        Jouw groep is niet zichtbaar op Inviplay, als beheerder nodig jij zelf
        mensen uit voor je groep
      </div>
    </div>
    <div class="mt-5">
      <button type="submit" class="btn btn-green btn-block btn-round">
        opslaan
      </button>
    </div>
  </form>
</template>

<script>
import FormRadioButtonCheckbox from '@/components/FormRadioButtonCheckbox'
import FormSelect from '@/components/FormSelect'

export default {
  name: 'GroupCreateOrEditForm',
  components: {
    FormRadioButtonCheckbox,
    FormSelect
  },
  props: {
    group: {
      type: Object,
      required: true,
      default: () => ({
        activityId: null,
        city: null,
        description: null,
        eventTypeId: null,
        image: null,
        imageUrl: null,
        name: null,
        visibility: 'public'
      })
    },
    activityTypes: {
      type: Array,
      required: true
    },
    eventTypes: {
      type: Array,
      required: true
    }
  },
  methods: {
    createGroup() {
      this.$emit('submit-group', this.group)
    },
    changeFile() {
      this.$emit('change-file', event)
    },
    removeFile() {
      this.$emit('remove-file')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.group-image-wrapper {
  position: relative;
  max-width: 640px;
  height: 200px;
  background-color: $ipGreyLighter;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-content: space-around;
}
.group-no-image {
  margin: 0 auto;
}
.btn-transparent-grey {
  color: $ipWhite;
  border: 0;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + label {
  display: block;
}
.remove-image {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $ipGreen;
  cursor: pointer;
  text-align: center;
  color: white;
}
</style>
