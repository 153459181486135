<template>
  <div>
    <h2>{{ title }}</h2>
    <div class="relative">
      <datetime
        v-model="dateTimeObj.dateTime"
        class="theme-green border h-11 rounded-lg p-1 px-4"
        :class="!disabled ? 'border-gray-600' : 'border-gray-300'"
        :disabled="disabled"
        :format="dateFormat"
        input-class="w-100 h-100 text-left text-sm disabled:bg-white pl-6"
        :min-datetime="today"
        :minute-step="15"
        :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
        @input="$emit('change-date-time', dateTimeObj)"
      />
      <icon-base
        class="absolute left-3 top-3"
        size="20"
        :class="disabled ? 'text-gray-300' : 'text-gray-600'"
      >
        <icon-calendar />
      </icon-base>
    </div>

    <div class="flex">
      <div class="w-1/2 flex-0 mr-2 flex flex-column">
        <h2>Starttijd</h2>
        <div class="relative">
          <datetime
            v-model="dateTimeObj.startDateTime"
            :disabled="!dateTimeObj.dateTime"
            type="time"
            :minute-step="15"
            :min-datetime="minTime"
            class="theme-green border h-11 rounded-lg p-1 px-4 bg-white"
            :class="
              dateTimeObj.dateTime ? 'border-gray-600' : 'border-gray-300'
            "
            input-class="w-100 h-100 text-center text-sm bg-white"
            :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
            @input="$emit('change-date-time', dateTimeObj)"
          />
          <icon-base
            class="absolute left-3 top-3"
            :class="!dateTimeObj.dateTime ? 'text-gray-300' : 'text-gray-600'"
            size="20"
          >
            <icon-clock />
          </icon-base>
        </div>
      </div>
      <div class="w-1/2 flex-0 ml-2 flex flex-column">
        <h2>Eindtijd</h2>
        <div class="relative">
          <datetime
            v-model="dateTimeObj.endDateTime"
            :disabled="!dateTimeObj.dateTime"
            type="time"
            :minute-step="15"
            :min-datetime="minTime"
            class="theme-green border h-11 rounded-lg p-1 px-4"
            :class="
              dateTimeObj.dateTime ? 'border-gray-600' : 'border-gray-300'
            "
            input-class="w-100 h-100 text-center text-sm bg-white"
            :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
            @input="$emit('change-date-time', dateTimeObj)"
          />
          <icon-base
            class="absolute left-3 top-3"
            size="20"
            :class="!dateTimeObj.dateTime ? 'text-gray-300' : 'text-gray-600'"
          >
            <icon-clock />
          </icon-base>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import IconBase from '@/components/IconBase'
import IconCalendar from '@/components/icons/IconCalendar'
import IconClock from '@/components/icons/IconClock'

export default {
  name: 'DateTimePicker',
  components: { IconBase, IconCalendar, IconClock },
  props: {
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dateTime: {
      type: String,
      default: undefined
    },
    endDateTime: {
      type: String,
      default: undefined
    },
    startDateTime: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      dateFormat: DateTime.DATE_HUGE,
      minTime: undefined,
      dateTimeObj: {
        dateTime: this.dateTime,
        endDateTime: this.endDateTime,
        startDateTime: this.startDateTime
      }
    }
  },
  computed: {
    today() {
      return DateTime.local().toString()
    }
  },
  watch: {
    dateTime(dt) {
      this.dateTimeObj.dateTime = dt
    },
    endDateTime(edt) {
      this.dateTimeObj.endDateTime = edt
    },
    startDateTime(sdt) {
      this.dateTimeObj.startDateTime = sdt
    },
    'dateTimeObj.dateTime'(dateTime) {
      this.minTime =
        DateTime.fromISO(dateTime).startOf('day').valueOf() ===
        DateTime.local().startOf('day').valueOf()
          ? DateTime.local().toString()
          : null
    }
  }
}
</script>
