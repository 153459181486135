<template>
  <div class="member-item">
    <div
      class="member-image-wrapper"
      :class="{
        'this-is-me': authId === participantId,
        medium: size === 'medium'
      }"
    >
      <div
        v-if="imageUrl"
        class="image-background"
        :style="{
          'background-image': `url(${imageUrl})`
        }"
      />
      <initials-avatar
        v-else
        :first-name="firstName"
        :last-name="lastName"
        :size="10"
      />
    </div>
  </div>
</template>

<script>
import InitialsAvatar from './InitialsAvatar.vue'
export default {
  name: 'GroupMemberItem',
  components: { InitialsAvatar },
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    participantId: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      authId: parseInt(this.$store.getters.authId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
.member-item:not(:first-child) {
  margin-left: -0.5rem;
}
.member-image-wrapper {
  height: 44px;
  width: 44px;
  position: relative;
  border-radius: 32px;
  background-color: $ipGreyLighter;
  border: 2px solid white;
  &.medium {
    height: 4rem;
    width: 4rem;
  }
}
.member-image-wrapper.this-is-me {
  border-color: $ipGreen;
}
.image-background {
  border-radius: 32px;
}
</style>
