<template>
  <img :src="type" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>
