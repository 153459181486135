<template>
  <form @submit.prevent="submitTeam">
    <div class="image-wrapper">
      <template v-if="team.imageUrl">
        <div
          v-if="team.imageUrl"
          class="image-background"
          :style="{
            'background-image': 'url(' + team.imageUrl + ')'
          }"
        />
        <div class="remove-image" @click="removeFile">X</div>
      </template>
      <template v-else>
        <div class="no-image">
          <input
            id="image"
            type="file"
            class="input-file"
            accept="image/*"
            @change="changeFile"
          />
          <label for="image" class="btn btn-transparent-grey">
            Kies een teamafbeelding
          </label>
        </div>
      </template>
    </div>
    <div class="form-group">
      <input
        v-model="team.name"
        type="text"
        class="form-control"
        placeholder="Wat is de naam van je team?"
        required
      />
    </div>
    <h2 class="page-title">Kies teamleden</h2>
    <p>Zoek naar de mensen die je wil toevoegen aan dit team</p>
    <template v-if="currentMembers.length">
      <GroupListItem
        v-for="member in currentMembers"
        :id="member.id"
        :key="member.id"
        :image-url="member.avatarUrl"
        :name="member.name"
        button-text="Verwijder"
        :show-arrow="false"
        :show-pointer="false"
        @button-action="removeMember"
      />
    </template>
    <div class="form-group">
      <input
        v-model="searchTerm"
        type="text"
        class="form-control"
        placeholder="Zoek op gebruikersnaam"
        @input="search"
      />
    </div>
    <div v-if="searchResults" class="search-results">
      <GroupListItem
        v-for="participant in searchResults"
        :id="participant.id"
        :key="participant.id"
        :image-url="participant.avatarUrl"
        :name="participant.name"
        button-text="Voeg toe"
        :show-arrow="false"
        :show-pointer="false"
        :button-disabled="
          currentMembers &&
          currentMembers.map((member) => member.id).includes(participant.id)
        "
        button-text-disabled="Toegevoegd"
        @button-action="addMember"
      />
    </div>
    <div class="mt-5">
      <button type="submit" class="btn btn-green btn-block btn-round">
        opslaan
      </button>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import GroupListItem from '@/components/GroupListItem'
import {
  PARTICIPANT_SEARCH,
  PARTICIPANT_SEARCH_CLEAR
} from '@/store/actions/participant'
import { TEAM_GET_MEMBERS } from '@/store/actions/team'

export default {
  name: 'GroupCreateOrEditForm',
  components: { GroupListItem },
  props: {
    team: {
      type: Object,
      required: true,
      default: () => ({
        image: null,
        imageUrl: null,
        name: null
      })
    }
  },
  data: function () {
    return {
      searchTerm: null,
      currentMembers: [],
      originalMembers: [],
      teamUpdated: false,
      groupId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      searchResults: (state) => state.participant.searchResults,
      teamMembers: (state) => state.team.teamMembers
    })
  },
  watch: {
    teamMembers(members) {
      this.originalMembers = [...members]
      this.currentMembers = [...members]
    }
  },
  created: function () {
    if (this.team.activeLineupId) {
      this.$store.dispatch(TEAM_GET_MEMBERS, {
        lineupId: this.team.activeLineupId
      })
    }
  },
  mounted: function () {
    this.$store.commit(PARTICIPANT_SEARCH_CLEAR)
  },
  methods: {
    submitTeam() {
      const teamToSubmit = {
        ...this.team,
        ...(this.teamUpdated && { members: this.currentMembers })
      }
      this.$emit('submit-team', teamToSubmit)
    },
    addMember(id) {
      const participant = this.searchResults.find((item) => item.id === id)
      this.currentMembers.push(participant)
      this.currentMembers.sort((a, b) => a - b)
      this.teamUpdated = !_.isEqual(this.currentMembers, this.originalMembers)
    },
    removeMember(id) {
      this.currentMembers = this.currentMembers.filter(
        (member) => member.id !== id
      )
      this.currentMembers.sort((a, b) => a - b)
      this.teamUpdated = !_.isEqual(this.currentMembers, this.originalMembers)
    },
    changeFile() {
      this.$emit('change-file', event)
    },
    removeFile() {
      this.$emit('remove-file')
    },
    search: _.debounce(function (e) {
      if (e.target.value.length > 2) {
        this.$store.dispatch(PARTICIPANT_SEARCH, {
          query: e.target.value,
          groupId: this.groupId
        })
      } else {
        this.$store.commit(PARTICIPANT_SEARCH_CLEAR)
      }
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.image-wrapper {
  position: relative;
  max-width: 640px;
  height: 200px;
  background-color: $ipGreyLighter;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-content: space-around;
}
.no-image {
  margin: 0 auto;
}
.btn-transparent-grey {
  color: $ipWhite;
  border: 0;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + label {
  display: block;
}
.remove-image {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $ipGreen;
  cursor: pointer;
  text-align: center;
  color: white;
}
</style>
