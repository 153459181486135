<template>
  <div class="item">
    <div class="image-wrapper">
      <div
        v-if="imageUrl"
        class="image-background"
        :style="{
          'background-image': `url(${imageUrl})`
        }"
      />
    </div>
    <div class="title">
      {{ name }}
    </div>
    <div v-if="type === 'match'" class="score">
      <template v-if="edit">
        <input
          v-model="newScore"
          type="text"
          class="form-control"
          placeholder=""
          @input="updateScore"
        />
      </template>
      <template v-else>
        {{ score }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupTeamListItem',
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    score: {
      type: Number,
      required: false,
      default: undefined
    },
    imageUrl: {
      type: String,
      required: false,
      default: ''
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      newScore: null
    }
  },
  computed: {},
  watch: {},
  created: function () {
    this.newScore = this.score
  },
  mounted: function () {},
  methods: {
    onButtonClick: function (id) {
      this.$emit('button-action', id)
    },
    updateScore: function (e) {
      this.$emit('update-score', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.item {
  display: flex;
  padding: 0.25rem 0;
  display: flex;
  flex-direction: row;
}
.item-pointer {
  cursor: pointer;
  &:hover {
    background-color: $greyLight;
    .arrow {
      color: $ipGreen;
    }
  }
}
.image-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: $ipGreyLighter;
  margin: auto 1rem auto 0;
}
.image-background {
  border-radius: 16px;
}
.title {
  font-weight: 600;
  font-size: 1.125rem;
  margin: auto 0;
  flex: 1;
}
.score {
  font-weight: 600;
  font-size: 1.125rem;
  margin: auto 0;
}
.form-control {
  width: 80px;
}
</style>
