<template>
  <div class="item-container">
    <div :class="isProposal ? 'item no-hover' : 'item'">
      <div class="image" :style="{ backgroundImage: 'url(' + image + ')' }" />
      <div class="details">
        <div class="d-flex flex-row">
          <div class="date flex-grow-1">
            {{ formattedDate || 'Datum in overleg' }}
          </div>
          <div class="numberOfParticipants">
            <template v-if="isProposal">
              <span v-if="maximumParticipants">
                Max. {{ maximumParticipants }} deelnemers
              </span>
              <span v-else>Geen maximum</span>
            </template>
            <template v-else>
              <RoundIcon :size="20" icon-class="fal fa-users" />
              {{ numberOfParticipants }}
              {{ maximumParticipants ? '/' + maximumParticipants : '' }}
            </template>
          </div>
        </div>
        <div class="title">
          {{ title }}
        </div>
        <div class="d-flex flex-row">
          <div class="location flex flex-grow-1 mt-2">
            <icon-base class="mr-2">
              <icon-pin />
            </icon-base>
            {{ location }}
          </div>
          <div class="cost">
            <span v-if="cost !== '0.00'">{{ $n(cost, 'currency') }}</span>
            <span v-else>Gratis</span>
          </div>
        </div>
        <div class="flex flex-grow-1 mt-2 text-small">
          {{ description }}
        </div>
      </div>
    </div>
    <div class="flex items-center w-full">
      <div
        v-if="isProposal"
        class="flex-1 text-small text-green-600 mt-2 ml-20"
      >
        Overleg in de groepschat over een datum
      </div>
      <div
        v-if="isOwner"
        class="flex text-small text-right text-green-600 mt-2 ml-2"
      >
        <button
          class="button-xs button-primary rounded-full"
          @click="() => openDateModal(eventId)"
        >
          prik datum
        </button>
        <button
          class="button-xs button-danger rounded-full text-xs px-1 ml-2"
          @click="() => removeEvent(eventId)"
        >
          <icon-base size="18">
            <icon-trash />
          </icon-base>
        </button>
      </div>
    </div>
    <modal :show="dateModalIsOpen" @close="closeDateModal">
      <template v-slot:header>Prik datum</template>
      <date-time-picker
        :disabled="false"
        title="Datum"
        :date-time="newDate.startDate"
        :start-date-time="newDate.startTime"
        :end-date-time="newDate.endTime"
        @change-date-time="changeDateTime"
      />
      <template v-slot:footer>
        <div class="flex w-full">
          <button
            class="button button-secondary w-full mr-4"
            @click="closeDateModal"
          >
            annuleren
          </button>
          <button class="button button-primary w-full" @click="addDate">
            toevoegen
          </button>
        </div>
      </template>
    </modal>
    <modal
      :show="successModalIsOpen"
      @close="
        () => {
          successModalIsOpen = false
          $emit('pick-date')
        }
      "
    >
      <template v-slot:header>Gelukt</template>
      <div>
        Je hebt een datum geprikt voor deze activiteiten, mensen kunnen zich nu
        gaan aanmelden. Veel plezier!
      </div>
    </modal>
    <modal :show="errorModalIsOpen" @close="errorModalIsOpen = false">
      <template v-slot:header>Oeps</template>
      <div>
        Er is iets fout gegaan. Probeer het nog een keer of neem contact op via
        contact@inviplay.nl
      </div>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import RoundIcon from '@/components/RoundIcon'
import IconBase from '@/components/IconBase'
import IconPin from '@/components/icons/IconPin'
import IconTrash from '@/components/icons/IconTrash'
import Modal from '@/components/Modal'
import DateTimePicker from '@/components/DateTimePicker'
import { DateTime } from 'luxon'
import api from '@/utils/api'

export default {
  name: 'GroupEventItem',
  components: {
    RoundIcon,
    IconBase,
    IconPin,
    Modal,
    DateTimePicker,
    IconTrash
  },
  props: {
    title: {
      type: String,
      required: true
    },
    startDateTime: {
      type: String,
      required: false,
      default: undefined
    },
    location: {
      type: String,
      required: true
    },
    numberOfParticipants: {
      type: Number,
      required: false,
      default: undefined
    },
    maximumParticipants: {
      type: Number,
      required: false,
      default: undefined
    },
    locationLatLng: {
      type: String,
      required: false,
      default: undefined
    },
    cost: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false,
      default: undefined
    },
    isProposal: {
      type: Boolean,
      required: false,
      default: false
    },
    description: {
      type: String,
      required: false,
      default: undefined
    },
    isOwner: {
      type: Boolean,
      required: false,
      default: false
    },
    eventId: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      dateModalIsOpen: false,
      successModalIsOpen: false,
      errorModalIsOpen: false,
      eventToAddDate: undefined,
      newDate: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined
      }
    }
  },
  computed: {
    formattedDate: function () {
      return this.startDateTime
        ? moment(this.startDateTime).format('dd D MMM HH:mm')
        : undefined
    }
  },
  methods: {
    openDateModal(eventId) {
      this.eventToAddDate = eventId
      this.dateModalIsOpen = true
    },
    closeDateModal() {
      this.dateModalIsOpen = false
      this.eventToAddDate = undefined
      this.newDate = {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined
      }
    },
    changeDateTime(dateTimeObj) {
      this.newDate.startDate = dateTimeObj.dateTime
      this.newDate.startTime = dateTimeObj.startDateTime
      this.newDate.endTime = dateTimeObj.endDateTime
    },
    async addDate() {
      const startDateTime = DateTime.fromISO(this.newDate.startDate).set({
        hour: DateTime.fromISO(this.newDate.startTime).hour,
        minute: DateTime.fromISO(this.newDate.startTime).minute
      })
      const endDateTime = DateTime.fromISO(this.newDate.startDate).set({
        hour: DateTime.fromISO(this.newDate.endTime).hour,
        minute: DateTime.fromISO(this.newDate.endTime).minute
      })
      try {
        const response = await api.post(
          `/event/${this.eventToAddDate}/addDate`,
          {
            startDateTime,
            endDateTime
          }
        )
        this.closeDateModal()
        if (response.status === 200) {
          this.successModalIsOpen = true
        } else {
          this.errorModalIsOpen = true
        }
      } catch (error) {
        this.closeDateModal()
        this.errorModalIsOpen = true
      }
    },
    async removeEvent(eventId) {
      try {
        const response = await api.delete(`/event/${eventId}`)
        if (response.status === 200) {
          // TODO: remove event from list
          this.$emit('remove-event', eventId)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.item-container {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.item {
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
}
.no-hover:hover {
  cursor: default !important;
}
.date {
  font-size: 0.875rem;
  color: #999;
  text-transform: uppercase;
}
.title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.location {
  font-size: 0.875rem;
  color: #999;
}
.numberOfParticipants {
  font-size: 0.875rem;
}
.cost {
  font-size: 0.875rem;
}
.image {
  width: 4rem;
  height: 4rem;
  background-size: cover;
  border-radius: 0.5rem;
  flex: 0 0 auto;
  margin-right: 1rem;
}
.details {
  flex: 1 1 auto;
}
</style>
