<template>
  <div class="has-tab-bar">
    <AppBar
      :title="group && group.name"
      :show-back-button="true"
      :back-button-path="`/groups/${groupId}`"
    />
    <SpinnerLoader v-if="loading" />
    <div v-else class="container">
      <h1 class="page-title my-4">Agenda</h1>
      <div class="select-row">
        <div class="form-group has-icon datepicker">
          <template v-if="!showFromDate">
            <Datepicker
              v-model="showFromDate"
              placeholder="Bekijk vanaf"
              input-class="form-control"
              class="theme-green"
              :min-datetime="new Date().toISOString()"
              :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
              @change-date="(date) => (showFromDate = date)"
            />
            <div class="input-icon">
              <i class="fal fa-calendar-alt" />
            </div>
          </template>
          <template v-else>
            <div class="filter-box">
              <div class="filter-title">
                Vanaf:
                <span style="font-weight: 600">{{ formattedFromDate }}</span>
              </div>
              <div class="filter-clear" @click="showFromDate = undefined">
                <i class="fal fa-times" />
              </div>
            </div>
          </template>
        </div>
        <div class="timeframe-select">
          <dropdown
            :disabled="false"
            :items="timeframeOptions"
            :selected="selectedTimeframe"
            empty-option="Bekijk alles"
            theme="rounded"
            @on-select="setTimeframe"
          />
        </div>
      </div>
      <div
        class="timeframe-navigator"
        :class="!selectedTimeframe && 'disabled'"
      >
        <div
          class="nav nav-prev"
          :class="prevDisabled && 'disabled'"
          @click="!prevDisabled && goPrev()"
        >
          <i class="fal fa-angle-left" />
        </div>
        <div class="nav-current">
          <template v-if="selectedTimeframe">
            {{ formattedTimeframe }}
          </template>
        </div>
        <div
          class="nav nav-next"
          :class="nextDisabled && 'disabled'"
          @click="!nextDisabled && goNext()"
        >
          <i class="fal fa-angle-right" />
        </div>
      </div>
      <div v-if="eventsToShow && eventsToShow.length" class="events">
        <GroupEventItem
          v-for="event in eventsToShow"
          :key="event.dateId"
          :title="event.name"
          :start-date-time="event.startDateTime"
          :end-date-time="event.endDateTime"
          :location="`${event.location.name}, ${event.location.city}`"
          :number-of-participants="event.numberOfParticipants"
          :maximum-participants="event.maximumParticipants"
          :cost="event.costFeeIncluded"
          :image="event.image"
          @click.native="
            routeToEventView(event.eventId, event.dateId, event.securityHash)
          "
        />
      </div>
      <div v-else>
        <p>
          Er zijn geen events deze
          {{
            selectedTimeframe === 'week'
              ? 'week'
              : selectedTimeframe === 'month'
              ? 'maand'
              : 'periode'
          }}
        </p>
      </div>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import Dropdown from '@/components/Dropdown'
import GroupEventItem from '@/components/GroupEventItem'
import Datepicker from '@/components/Datepicker'
import { mapState } from 'vuex'
import {
  GROUP_GET_DETAILS,
  GROUP_GET_UPCOMING_EVENTDATES
} from '@/store/actions/group'
import moment from 'moment'

export default {
  name: 'GroupsEvents',
  components: {
    AppBar,
    SpinnerLoader,
    TabBar,
    GroupEventItem,
    Datepicker,
    Dropdown
  },
  data() {
    return {
      groupId: this.$route.params.id,
      showFromDate: undefined,
      selectedTimeframe: undefined,
      timeframeOptions: [
        {
          key: 'week',
          value: 'Bekijk per week'
        },
        {
          key: 'month',
          value: 'Bekijk per maand'
        }
      ],
      currentWeek: moment().week(),
      currentMonth: moment().month(),
      allEvents: []
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.group.loading,
      upcomingEvents: (state) => state.group.upcomingEvents,
      group: (state) => state.group.groupDetails
    }),
    formattedTimeframe: function () {
      if (this.selectedTimeframe === 'week') {
        const start = moment()
          .week(this.currentWeek)
          .startOf('week')
          .format('D MMM YYYY')
        const end = moment()
          .week(this.currentWeek)
          .endOf('week')
          .format('D MMM YYYY')
        return `${start} - ${end}`
      } else {
        const month = moment()
          .month(this.currentMonth)
          .startOf('month')
          .format('MMMM YYYY')
        return `${month.charAt(0).toUpperCase()}${month.slice(1)}`
      }
    },
    eventsPerMonth: function () {
      return this.splitInMonths(this.allEvents)
    },
    eventsPerWeek: function () {
      return this.splitInWeeks(this.allEvents)
    },
    firstWeek: function () {
      const items = Object.keys(this.eventsPerWeek)
      return items.length
        ? items.reduce((lowest, item) => {
            return lowest === undefined || lowest > parseInt(item)
              ? parseInt(item)
              : lowest
          }, undefined)
        : undefined
    },
    firstMonth: function () {
      const items = Object.keys(this.eventsPerMonth)
      return items.length
        ? items.reduce((lowest, item) => {
            return lowest === undefined || lowest > parseInt(item)
              ? parseInt(item)
              : lowest
          }, undefined)
        : undefined
    },
    lastWeek: function () {
      const items = Object.keys(this.eventsPerWeek)
      return items.length
        ? items.reduce((highest, item) => {
            return highest === undefined || highest < parseInt(item)
              ? parseInt(item)
              : highest
          }, undefined)
        : undefined
    },
    lastMonth: function () {
      const items = Object.keys(this.eventsPerMonth)
      return items.length
        ? items.reduce((highest, item) => {
            return highest === undefined || highest < parseInt(item)
              ? parseInt(item)
              : highest
          }, undefined)
        : undefined
    },
    prevDisabled: function () {
      if (this.selectedTimeframe === 'week') {
        return this.currentWeek === this.firstWeek
      } else if (this.selectedTimeframe === 'month') {
        return this.currentMonth === this.firstMonth
      } else {
        return false
      }
    },
    nextDisabled: function () {
      if (this.selectedTimeframe === 'week') {
        return this.currentWeek === this.lastWeek
      } else if (this.selectedTimeframe === 'month') {
        return this.currentMonth === this.lastMonth
      } else {
        return false
      }
    },
    eventsToShow: function () {
      if (this.selectedTimeframe === 'week') {
        return this.eventsPerWeek[this.currentWeek]
      } else if (this.selectedTimeframe === 'month') {
        return this.eventsPerMonth[this.currentMonth]
      } else {
        return this.allEvents
      }
    },
    formattedFromDate: function () {
      return moment(this.showFromDate).format("DD MMM 'YY")
    }
  },
  watch: {
    group() {
      if (!this.upcomingEvents.length) {
        this.$store.dispatch(GROUP_GET_UPCOMING_EVENTDATES, {
          groupId: this.groupId,
          skip: 0,
          limit: 0
        })
      }
    },
    upcomingEvents(events) {
      this.allEvents = events
    },
    showFromDate(date) {
      if (date) {
        this.allEvents = this.upcomingEvents.filter((event) => {
          return moment(event.startDateTime).isAfter(date)
        })
      } else {
        this.allEvents = this.upcomingEvents
      }
      this.currentWeek = this.firstWeek
      this.currentMonth = this.firstMonth
    }
  },
  created: function () {
    if (!this.group) {
      this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    } else if (!this.upcomingEvents || !this.upcomingEvents.length) {
      this.$store.dispatch(GROUP_GET_UPCOMING_EVENTDATES, {
        groupId: this.groupId,
        skip: 0,
        limit: 0
      })
    } else {
      this.allEvents = this.upcomingEvents
    }
  },
  mounted: function () {},
  methods: {
    routeToEventView: function (id, dateId, securityHash) {
      this.$router.push(
        `/events/${dateId}/${securityHash}/?origin=groups/${this.groupId}/events`
      )
    },
    // changeDate(val) {
    //   this.showFromDate = val
    //   this.allEvents = this.upcomingEvents.filter((event) => {
    //     return moment(event.startDateTime).isAfter(val)
    //   })
    //   this.currentWeek = this.firstWeek
    //   this.currentMonth = this.firstMonth
    // },
    setTimeframe(range) {
      this.selectedTimeframe = range
      this.currentWeek = this.allEvents.length
        ? moment(this.allEvents[0].startDateTime).week()
        : moment().week()
      this.currentMonth = this.allEvents.length
        ? moment(this.allEvents[0].startDateTime).month()
        : moment().month()
    },
    goNext() {
      if (this.selectedTimeframe === 'week') {
        this.currentWeek += 1
      } else if (this.selectedTimeframe === 'month') {
        this.currentMonth += 1
      }
    },
    goPrev() {
      if (this.selectedTimeframe === 'week') {
        this.currentWeek -= 1
      } else if (this.selectedTimeframe === 'month') {
        this.currentMonth -= 1
      }
    },
    splitInMonths(events) {
      return events.reduce((arr, curr) => {
        const month = moment(curr.startDateTime).month()
        return {
          ...arr,
          [month]: !Object.keys(arr).includes(month.toString())
            ? [curr]
            : [...arr[month], curr]
        }
      }, {})
    },
    splitInWeeks(events) {
      return events.reduce((arr, curr) => {
        const week = moment(curr.startDateTime).week()
        return {
          ...arr,
          [week]: !Object.keys(arr).includes(week.toString())
            ? [curr]
            : [...arr[week], curr]
        }
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.select-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.datepicker {
  flex: 1;
  margin-right: 0.5rem;
  margin-bottom: 0 !important;
}
.timeframe-select {
  flex: 1;
  margin-left: 0.5rem;
}
.timeframe-navigator {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  height: 2rem;
  &.disabled {
    color: #ccc;
  }
}
.nav {
  flex: 0 0 auto;
  height: 2.5rem;
  font-size: 1.75rem;
  i {
    line-height: inherit !important;
  }
  &.disabled {
    color: #ccc;
  }
}
.nav-prev {
  padding-right: 1rem;
}
.nav-next {
  padding-left: 1rem;
}
.nav-current {
  flex: 1 0 auto;
  text-align: center;
}
.events {
  margin-top: 0.5rem;
}
.filter-box {
  display: flex;
  border: 1px solid #9a9a9a;
  border-radius: 0.5rem;
  height: 50px;
  font-size: 0.875rem;
  align-items: center;
  padding: 0 0 0 0.875rem;
}
.filter-title {
  flex: 1;
}
.filter-clear {
  padding: 0.875rem 0.5rem 0.875rem 0.375rem;
}
</style>
