<template>
  <div class="has-tab-bar">
    <AppBar :title="group && group.name" :show-back-button="mode === 'view'" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <!-- ADD MODE -->
      <template v-if="mode === 'add'">
        <h1 class="page-title mt-4 mb-5">Nieuwe wedstrijd</h1>
        <form @submit.prevent="addMatch">
          <div class="form-group">
            <FormSelect
              v-model="newMatch.dateId"
              :items="eventDates"
              empty-text="Kies een event"
              :border-size="2"
              :required="true"
            />
          </div>
          <div v-if="newMatch.dateId" class="form-group">
            <FormSelect
              v-model="newMatch.home_id"
              :items="teamsWithLineups"
              empty-text="Kies een team"
              :border-size="2"
              :required="true"
            />
          </div>
          <div v-if="newMatch.home_id" class="form-group">
            <FormSelect
              v-model="newMatch.away_id"
              :items="
                teamsWithLineups.filter((team) => team.key !== newMatch.home_id)
              "
              empty-text="Kies een tegenstander"
              :border-size="2"
              :required="true"
            />
          </div>
          <div v-if="newMatch.home_id && newMatch.away_id" class="my-4">
            <button type="submit" class="btn btn-green btn-block btn-round">
              opslaan
            </button>
          </div>
        </form>
        <a
          href="#"
          class="btn btn-white-green-green btn-block btn-round mt-4"
          @click.prevent="setModeToView"
        >
          annuleren
        </a>
      </template>
      <!-- VIEW OR EDIT MODE -->
      <template v-if="mode === 'view' || mode === 'edit'">
        <h1 class="page-title mt-4 mb-5">Wedstrijden</h1>
        <template v-if="selectedDate && selectedDate.eventTitle">
          <div class="d-flex flex-row scroller">
            <div
              class="arrow"
              :class="!prevEnabled && 'disabled'"
              @click="selectPrev"
            >
              <i class="fal fa-angle-left" />
            </div>
            <div class="d-flex flex-column main">
              <div class="event-title">
                {{ selectedDate.eventTitle }}
              </div>
              <div class="event-date">
                {{ selectedDate.eventDate }}
              </div>
            </div>
            <div
              class="arrow"
              :class="!nextEnabled && 'disabled'"
              @click="selectNext"
            >
              <i class="fal fa-angle-right" />
            </div>
          </div>
          <div
            v-for="match in selectedDate.matches"
            :key="match.id"
            class="match-item"
          >
            <GroupTeamListItem
              :id="match.home_id"
              :name="teamListById[match.home_id]"
              :score="match.home_score"
              :edit="mode === 'edit'"
              type="match"
              @update-score="
                (val) =>
                  updateScore({ id: match.id, field: 'home_score', score: val })
              "
            />
            <GroupTeamListItem
              :id="match.away_id"
              :name="teamListById[match.away_id]"
              :score="match.away_score"
              :edit="mode === 'edit'"
              type="match"
              @update-score="
                (val) =>
                  updateScore({ id: match.id, field: 'away_score', score: val })
              "
            />
          </div>
          <template v-if="foundResult">
            <h2 class="page-title mt-5">Eindstand</h2>
            <GroupListItem
              v-for="(result, rank) in foundResult.points"
              :id="parseInt(result.teamId)"
              :key="parseInt(result.teamId)"
              :name="teamListById[result.teamId]"
              :text-left="(rank + 1).toString()"
              :text-right="result.points.toString()"
              :show-arrow="false"
              :show-pointer="false"
              :show-image="false"
            />
            <!-- <div v-for="result in foundResult.points" :key="result.teamId">
              <span>
                {{ result.rank }}. {{ teamListById[result.teamId] }} -
                {{ result.points }} pnt
              </span>
            </div> -->
          </template>
        </template>
        <template v-else>
          <p>Er zijn nog geen wedstrijden aangemaakt voor deze competitie</p>
        </template>
        <div v-if="selectedDate && selectedDate.matches" class="my-4">
          <template v-if="mode === 'edit'">
            <a
              href="#"
              class="btn btn-green btn-block btn-round mt-3"
              @click.prevent="updateMatches"
            >
              uitslagen opslaan
            </a>
            <a
              href="#"
              class="btn btn-white-green-green btn-block btn-round mt-3"
              @click.prevent="setModeToView"
            >
              annuleren
            </a>
          </template>
        </div>
        <template v-if="mode === 'view'">
          <a
            href="#"
            class="btn btn-white-green-green btn-block btn-round mt-5"
            @click.prevent="setModeToAdd"
          >
            nieuwe wedstrijd
          </a>
          <a
            href="#"
            class="btn btn-white-green-green btn-block btn-round mt-3"
            @click.prevent="setModeToEdit"
          >
            uitslagen invullen
          </a>
          <a
            v-if="teamsPlayedOnDate.length"
            href="#"
            class="btn btn-green btn-block btn-round mt-3"
            @click.prevent="openResultModal"
          >
            <span v-if="foundResult">eindstand aanpassen</span>
            <span v-else>eindstand opslaan</span>
          </a>
          <SaveResultModal
            v-if="teamsPlayedOnDate.length && selectedDate"
            :show-modal="showModal"
            :teams="teamsPlayedOnDate"
            :title="selectedDate.eventTitle || 'Event'"
            :date="selectedDate.eventDate || 'EventDate'"
            @close-modal="showModal = false"
            @save-result="(result) => processResult(result)"
          />
          <modal :show="showFeedbackModal" @close="showFeedbackModal = false">
            <template v-slot:header>Opgeslagen!</template>
            <template>De eindstand is succesvol opgeslagen</template>
            <template v-slot:footer>
              <a
                href="#"
                class="btn btn-white-green-green btn-block btn-round"
                @click.prevent="showFeedbackModal = false"
              >
                Terug naar overzicht
              </a>
            </template>
          </modal>
        </template>
      </template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import GroupTeamListItem from '@/components/GroupTeamListItem'
import FormSelect from '@/components/FormSelect'
import SaveResultModal from '@/components/SaveResultModal'
import GroupListItem from '@/components/GroupListItem'
import Modal from '@/components/Modal'
import { mapState } from 'vuex'
import { LEAGUE_GET_MATCHES, LEAGUE_GET_DETAILS } from '@/store/actions/league'
import { GROUP_GET_DETAILS, GROUP_GET_EVENTS } from '@/store/actions/group'
import {
  MATCH_ADD,
  MATCH_UPDATE,
  MATCH_RESET_STATE,
  MATCH_SET_SELECTED_DATE
} from '@/store/actions/match'
import { RESULT_ADD, RESULT_FIND } from '@/store/actions/result'

export default {
  name: 'GroupsLeagueMatches',
  components: {
    AppBar,
    SpinnerLoader,
    TabBar,
    GroupTeamListItem,
    FormSelect,
    SaveResultModal,
    Modal,
    GroupListItem
  },
  data() {
    return {
      groupId: this.$route.params.id,
      leagueId: this.$route.params.leagueId,
      dateIds: [],
      nextEnabled: false,
      prevEnabled: false,
      mode: 'view',
      newMatch: {
        dateId: null,
        home_id: null,
        away_id: null
      },
      eventDates: [],
      teamsWithLineups: [],
      selectedDate: null,
      updatedScores: {},
      showModal: false,
      showFeedbackModal: false
    }
  },
  computed: {
    ...mapState({
      loading: (state) =>
        state.group.loading ||
        state.league.loading ||
        state.match.loading ||
        state.team.loading,
      matches: (state) => state.league.matches,
      league: (state) => state.league.leagueDetails,
      group: (state) => state.group.groupDetails,
      teamListById: (state) => state.team.teamListById,
      groupEvents: (state) => state.group.events,
      addedMatch: (state) => state.match.addedMatch,
      matchStatus: (state) => state.match.status,
      addedResult: (state) => state.result.addedResult,
      foundResult: (state) => state.result.foundResult,
      selectedDateId: (state) => state.match.selectedMatchId
    }),
    teamsPlayedOnDate: function () {
      const ids =
        this.selectedDate && this.selectedDate.matches
          ? this.selectedDate.matches.reduce(
              (teamIds, match) => [
                ...teamIds,
                ...(!teamIds.includes(match.home_id) && [match.home_id]),
                ...(!teamIds.includes(match.away_id) && [match.away_id])
              ],
              []
            )
          : []
      return ids.map((id) => ({ key: id, value: this.teamListById[id] }))
    }
  },
  watch: {
    matches(allMatches) {
      this.dateIds = Object.keys(allMatches)
      if (!this.selectedDateId) {
        this.$store.commit(MATCH_SET_SELECTED_DATE, this.dateIds[0])
      }
      this.nextEnabled =
        this.dateIds.length > this.dateIds.indexOf(this.selectedDateId) + 1
      this.selectedDate = { ...allMatches[this.selectedDateId] }
    },
    group() {
      this.teamsWithLineups = this.group.teams.map((team) => ({
        ...(team.lineup && {
          key: team.id.toString(),
          value: team.name
        })
      }))
    },
    groupEvents(events) {
      this.eventDates = events.map((event) => ({
        key: event.dateId.toString(),
        value: `${event.name} - ${event.date} ${event.startTime}`
      }))
    },
    addedMatch(match) {
      if (match) {
        this.newMatch = {
          dateId: null,
          home_id: null,
          away_id: null
        }
        this.setModeToView()
      }
    },
    matchStatus(status) {
      if (status === 'updated') {
        this.setModeToView()
      }
    },
    addedResult(result) {
      if (result && Object.keys(result).length) {
        this.showFeedbackModal = true
        this.$store.dispatch(RESULT_FIND, {
          leagueId: this.leagueId,
          dateId: result.dateID
        })
      }
    },
    selectedDateId(dateId) {
      this.selectedDate = { ...this.matches[dateId] }
      this.$store.dispatch(RESULT_FIND, {
        leagueId: this.leagueId,
        dateId
      })
    }
  },
  created: function () {
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    this.$store.dispatch(LEAGUE_GET_DETAILS, this.leagueId)
    this.$store.dispatch(GROUP_GET_EVENTS, { groupId: this.groupId })
    this.$store.dispatch(LEAGUE_GET_MATCHES, { leagueId: this.leagueId })
  },
  mounted: function () {},
  methods: {
    refetchAllMatches() {
      this.$store.commit(MATCH_RESET_STATE)
      this.$store.dispatch(LEAGUE_GET_MATCHES, { leagueId: this.leagueId })
    },
    selectNext() {
      const selectedIndex = this.dateIds.indexOf(this.selectedDateId)
      if (this.nextEnabled) {
        this.$store.commit(
          MATCH_SET_SELECTED_DATE,
          this.dateIds[selectedIndex + 1]
        )
        if (!this.dateIds[selectedIndex + 2]) {
          this.nextEnabled = false
        }
        this.prevEnabled = true
      }
    },
    selectPrev() {
      const selectedIndex = this.dateIds.indexOf(this.selectedDateId)
      if (this.prevEnabled) {
        this.$store.commit(
          MATCH_SET_SELECTED_DATE,
          this.dateIds[selectedIndex - 1]
        )
        if (!this.dateIds[selectedIndex - 2]) {
          this.prevEnabled = false
        }
        this.nextEnabled = true
      }
    },
    setModeToView() {
      this.refetchAllMatches()
      this.mode = 'view'
    },
    setModeToAdd() {
      this.mode = 'add'
    },
    setModeToEdit() {
      this.mode = 'edit'
    },
    addMatch() {
      this.$store.dispatch(MATCH_ADD, {
        ...this.newMatch,
        leagueId: this.leagueId
      })
      this.refetchAllMatches()
    },
    updateMatches() {
      this.$store.dispatch(MATCH_UPDATE, this.updatedScores)
      this.refetchAllMatches()
    },
    updateScore({ id, field, score }) {
      this.updatedScores = {
        ...this.updatedScores,
        [id]: {
          ...this.updatedScores[id],
          [field]: parseInt(score)
        }
      }
    },
    async openResultModal() {
      this.showModal = true
    },
    processResult(result) {
      this.showModal = false
      const ranks = Object.keys(result)
      const points = ranks.map((rank) => ({
        rank: parseInt(rank),
        teamId: parseInt(result[rank]),
        points: (ranks.length - rank) * 10
      }))
      this.$store.dispatch(RESULT_ADD, {
        leagueId: this.leagueId,
        dateId: parseInt(this.selectedDateId),
        points
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.scroller {
  align-items: center;
  margin-bottom: 2rem;
}
.arrow {
  font-size: 2rem;
  width: 2rem;
  align-content: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: $ipGreen;
  }
}
.disabled {
  color: $ipGreyLighter;
  &:hover {
    cursor: default;
    color: $ipGreyLighter;
  }
}
.main {
  text-align: center;
  flex: 1;
}
.event-title {
  font-size: 1.25rem;
}
.match-item {
  margin: 2rem auto;
}
</style>
