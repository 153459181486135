<template>
  <div class="has-tab-bar">
    <AppBar
      :title="groupId ? 'Groepsinstellingen' : 'Nieuwe groep aanmaken'"
      :show-back-button="true"
      :show-profile-button="false"
      :back-button-path="groupId ? `/groups/${groupId}` : '/groups'"
    />

    <div class="container">
      <template v-if="groupId">
        <template v-if="!participantIsOwner && !participantIsAdmin">
          <div class="error">
            <p>
              Je bent niet de beheerder van deze groep en hebt dus geen rechten
              om iets te wijzigen
            </p>
            <div class="mt-5">
              <a
                href="#"
                class="btn btn-green btn-block btn-round"
                @click.prevent="routeToGroup(groupId)"
              >
                terug naar de groep
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- existing group is updated -->
          <template v-if="updatedGroup">
            <h1 class="page-title">Gelukt!</h1>
            <p class="mt-5">
              Je hebt de aanpassingen voor
              <strong>{{ updatedGroup.name }}</strong>
              opgeslagen
            </p>
            <div class="mt-5">
              <a
                href="#"
                class="btn btn-green btn-block btn-round"
                @click.prevent="routeToGroup(updatedGroup.id)"
              >
                bekijk je groep
              </a>
            </div>
          </template>
          <template v-else>
            <!-- Form to update group -->

            <div v-if="!loading && error" class="error">
              <p>Oeps er ging iets fout, probeer het nog een keer</p>
            </div>

            <GroupCreateOrEditForm
              :group="group"
              :activity-types="activityTypes"
              :event-types="eventTypes"
              @submit-group="updateGroup"
              @remove-file="onFileRemove"
              @change-file="onFileChange"
            />
            <a
              href="#"
              class="btn btn-outline-danger btn-block btn-round mt-3"
              @click.prevent="showDeleteModal = true"
            >
              verwijder groep
            </a>
            <modal :show="showDeleteModal" @close="showDeleteModal = false">
              <template v-slot:header>
                {{ group.name }} wordt verwijderd
              </template>
              <template>
                <p>Weet je zeker dat je {{ group.name }} wilt verwijderen?</p>
                <div class="alert alert-danger mt-8">
                  Alle teams, competities, uitslagen en rankings gaan verloren.
                  Dit kan niet ongedaan worden gemaakt!
                </div>
              </template>
              <template v-slot:footer>
                <a
                  href="#"
                  class="btn btn-danger btn-block btn-round"
                  @click.prevent="deleteGroup"
                >
                  JA, verwijderen
                </a>
                <a
                  href="#"
                  class="btn btn-white-green-green btn-block btn-round"
                  @click.prevent="showDeleteModal = false"
                >
                  NEE, annuleren
                </a>
              </template>
            </modal>
            <modal :show="showDeletedIsDone" @close="routeToGroups">
              <template v-slot:header>Gelukt!</template>
              <template>
                <span>{{ group.name }} is verwijderd!</span>
              </template>
              <template v-slot:footer>
                <a
                  href="#"
                  class="btn btn-white-green-green btn-block btn-round"
                  @click.prevent="routeToGroups"
                >
                  Terug naar groepen
                </a>
              </template>
            </modal>
          </template>
        </template>
      </template>

      <template v-else>
        <template v-if="!loading && createdGroup">
          <!-- New group is created -->

          <h1 class="page-title">Gelukt!</h1>
          <p class="mt-5">
            Je bent nu de beheerder van de groep
            <strong>{{ createdGroup.name }}</strong>
          </p>
          <div class="mt-5">
            <a
              href="#"
              class="btn btn-green btn-block btn-round"
              @click.prevent="routeToGroup(createdGroup.id)"
            >
              bekijk je groep
            </a>
          </div>
        </template>

        <template v-else>
          <!-- Form for new group -->

          <div v-if="!loading && error" class="error">
            <p>Oeps er ging iets fout, probeer het nog een keer</p>
          </div>

          <GroupCreateOrEditForm
            :group="group"
            :activity-types="activityTypes"
            :event-types="eventTypes"
            @submit-group="createGroup"
            @remove-file="onFileRemove"
            @change-file="onFileChange"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import Modal from '@/components/Modal'
import GroupCreateOrEditForm from '@/components/GroupCreateOrEditForm'
import {
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_RESET_STATE,
  GROUP_GET_DETAILS,
  GROUP_DELETE
} from '@/store/actions/group'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { EVENT_TYPE_LIST } from '@/store/actions/event_type'
import { getOrientation } from '@/utils/getOrientation'
import { mapState, mapGetters } from 'vuex'
import data from '@/utils/data'

export default {
  name: 'GroupsCreateOrEdit',
  components: {
    AppBar,
    GroupCreateOrEditForm,
    Modal
  },
  data() {
    return {
      group: {
        activityId: null,
        city: null,
        description: null,
        eventTypeId: null,
        image: null,
        imageUrl: null,
        name: null,
        visibility: 'public'
      },
      activityTypes: [],
      imageOrientation: null,
      eventTypes: [],
      groupId: this.$route.params.id,
      participantIsOwner: false,
      showDeleteModal: false,
      showDeletedIsDone: false
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      createdGroup: (state) => state.group.createdGroup,
      updatedGroup: (state) => state.group.updatedGroup,
      error: (state) => state.group.error,
      loading: (state) => state.group.loading,
      activityList: (state) => state.activity.activityList,
      eventTypeList: (state) => state.eventTypes.eventTypeList,
      groupDetails: (state) => state.group.groupDetails
    }),
    participantIsAdmin: function () {
      return this.group?.admins?.includes(parseInt(this.authId))
    }
  },
  watch: {
    activityList(list) {
      this.activityTypes = list.map((item) => ({
        key: item.id.toString(),
        value: item.name
      }))
    },
    eventTypeList(list) {
      this.eventTypes = list.map((item) => ({
        key: item.id.toString(),
        value: data.formatEventTypeItems(item.name)
      }))
    },
    groupDetails(groupDetails) {
      this.group = {
        ...groupDetails,
        activityId: groupDetails.activityId?.toString(),
        eventTypeId: groupDetails.eventTypeId?.toString(),
        privateGroup: groupDetails.private,
        visibility: groupDetails.private
          ? 'privateGroup'
          : groupDetails.needsPermissionToAdd
          ? 'needsPermissionToAdd'
          : 'public'
      }
      this.participantIsOwner = this.getParticipantIsOwner()
    },
    updatedGroup() {
      this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    }
  },
  created: function () {
    this.$store.commit(GROUP_RESET_STATE)
    this.$store.dispatch(ACTIVITY_LIST)
    this.$store.dispatch(EVENT_TYPE_LIST)
    if (this.groupId) {
      this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    }
    this.participantIsOwner = this.getParticipantIsOwner()
  },
  methods: {
    createGroup: function () {
      this.$store.dispatch(GROUP_CREATE, this.group)
    },
    updateGroup: function () {
      this.$store.dispatch(GROUP_UPDATE, {
        groupId: this.groupId,
        group: this.group
      })
    },
    onFileChange: function (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.group.image = files[0]
      const vm = this
      getOrientation(files[0], function (orientation) {
        switch (orientation) {
          case 3:
            vm.imageOrientation = 'orientation-3'
            break
          case 6:
            vm.imageOrientation = 'orientation-6'
            break
          case 8:
            vm.imageOrientation = 'orientation-8'
            break
        }
      })
      // Set image url in view
      const reader = new FileReader()
      reader.onload = (e) => {
        vm.group.imageUrl = e.target.result
      }
      reader.readAsDataURL(files[0])
    },
    onFileRemove: function () {
      this.group.image = null
      this.group.imageUrl = null
    },
    routeToGroup: function (groupId) {
      this.$router.push(`/groups/${groupId}`)
    },
    routeToGroups: function () {
      this.$router.push('/groups')
    },
    getParticipantIsOwner: function () {
      return this.group.ownerId === parseInt(this.authId)
    },
    deleteGroup() {
      this.$store.dispatch(GROUP_DELETE, this.groupId)
      this.showDeleteModal = false
      this.showDeletedIsDone = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.container {
  margin-top: 2rem;
}
</style>
