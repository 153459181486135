<template>
  <label
    :style="{ color: labelColor }"
    :class="{
      'checkbox-right': checkboxPosition === 'right',
      'font-size-small': fontSize === 'small'
    }"
  >
    <Icon v-if="icon" class="icon" :type="icon" />
    <div class="label">{{ label }}</div>
    <slot />
    <input
      v-model="selectedValue"
      type="radio"
      :value="value"
      :name="name"
      :required="required"
      :disabled="disabled"
    />
    <div
      :class="{
        'checkbox-size-small': checkboxSize === 'small',
        indicator: indicator,
        'date-list-checkbox': dateListCheckbox === true,
        'date-list-label': dateListLabel
      }"
    />
  </label>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'FormRadioButtonCheckbox',
  components: {
    Icon
  },
  props: {
    selected: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    labelColor: {
      type: String,
      required: false,
      default: '#000'
    },
    checkboxPosition: {
      type: String,
      required: false,
      default: 'left'
    },
    fontSize: {
      type: String,
      required: false,
      default: ''
    },
    checkboxSize: {
      type: String,
      required: false,
      default: ''
    },
    dateListCheckbox: {
      type: Boolean,
      required: false
    },
    dateListLabel: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      indicator: true
    }
  },
  computed: {
    selectedValue: {
      get: function () {
        return this.selected
      },
      set: function () {
        this.$emit('change', this.value)
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';

label {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 1.125rem;
}
label.font-size-small {
  font-size: 1rem;
}
label.font-size-small .label {
  padding-top: 4px;
}

@media all and (max-width: 575px) {
  label.font-size-small {
    font-size: 1rem;
  }
}

.icon {
  width: 32px;
  display: inline-block;
  margin-right: 5px;
}

.icon img {
  width: 100%;
  margin-bottom: 4px;
}

label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
label .indicator {
  position: absolute;
  top: 0px;
  left: 0;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid $ipGreen;
  border-radius: 4px;
}
label .indicator.checkbox-size-small {
  top: 5px;
  left: 5px;
  width: 22px;
  height: 22px;
  border: 2px solid $ipBlack;
}
label input:checked ~ .indicator {
  background-color: $ipGreen;
}

label .indicator:after {
  content: '';
  position: absolute;
  display: none;
  left: 8px;
  top: 1px;
  width: 11px;
  height: 21px;
  border: solid $ipWhite;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}
label .checkbox-size-small.indicator:after {
  left: 6px;
  top: 1px;
  width: 6px;
  height: 13px;
  border: solid $ipBlack;
  border-width: 0 3px 3px 0;
}

@media all and (max-width: 575px) {
  label .indicator.checkbox-size-small {
    top: 2px;
    left: 2px;
  }
}

label input:disabled ~ .indicator:after {
  border-color: #7b7b7b;
}
label input:checked ~ .indicator:after {
  display: block;
}
label.checkbox-right {
  padding-left: 0;
  padding-right: 40px;
  font-size: 1rem;
}
label.checkbox-right .indicator {
  left: auto;
  right: 0;
}
label.checkbox-right .date-list-checkbox {
  right: 18px;
}
label.checkbox-right .date-list-label {
  display: none;
}
</style>
