<template>
  <div class="screen-groups-list has-tab-bar">
    <AppBar
      title="Groepen"
      :show-back-button="false"
      back-button-path="/groups"
    ></AppBar>
    <div v-if="!loading" class="container">
      <div class="title-with-button">
        <h2 class="page-title">Jouw groepen</h2>
        <a
          href="#"
          class="btn btn-green btn-round btn-small btn-float"
          @click.prevent="routeToCreateGroup"
        >
          maak groep
        </a>
      </div>

      <!-- Groups Joined -->
      <template
        v-if="groupsJoined && groupsJoined.data && groupsJoined.data.length"
      >
        <div class="d-flex flex-row">
          <GroupItem
            v-for="group in groupsJoined.data.slice(0, 3)"
            :key="group.id"
            view="blocks"
            :name="group.name"
            :image-url="group.imageUrl"
            @click.native="routeToGroupDetailPage(group.id)"
          />
        </div>
        <a
          v-if="groupsJoined.data.length > 3"
          href="/groups/all"
          class="link"
          @click.prevent="routeToAllGroups(VIEW_OWN)"
        >
          Bekijk al jouw groepen ({{ groupsJoined.data.length }})
        </a>
      </template>
      <template v-else>
        <div class="noGroups">
          Je zit nog niet in een groep. Meld je aan bij een bestaande groep of
          start een nieuwe.
        </div>
      </template>

      <!-- Groups nearby -->
      <div class="content-part">
        <h2 class="page-title">Populaire groepen</h2>
        <div
          v-if="allGroups && allGroups.data && allGroups.data.length"
          class="d-flex flex-column"
        >
          <GroupItem
            v-for="group in allGroups.data.slice(0, 5)"
            :key="group.id"
            view="list"
            :name="group.name"
            :city="group.city"
            :member-count="group.acceptedMembers"
            :image-url="group.imageUrl"
            @click.native="routeToGroupDetailPage(group.id)"
          />
          <a
            href="/groups/all"
            class="link"
            @click.prevent="routeToAllGroups(VIEW_ALL)"
          >
            Bekijk alle groepen
          </a>
        </div>
        <div v-else class="noGroups">
          Er zijn nog geen groepen aangemaakt waar je je voor kunt aanmelden.
        </div>
      </div>

      <div class="mt-5">
        <a
          href="#"
          class="btn btn-block btn-white-green-green btn-round"
          @click.prevent="routeToSearchGroup"
        >
          zoek een groep
        </a>
        <a
          href="#"
          class="btn btn-block btn-green btn-round"
          @click.prevent="routeToCreateGroup"
        >
          maak een nieuwe groep
        </a>
      </div>
    </div>
    <SpinnerLoader v-else />
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import GroupItem from '@/components/GroupItem'
import {
  GROUP_GET_ALL,
  GROUP_GET_JOINED,
  GROUP_RESET_STATE
} from '@/store/actions/group'
import { mapState } from 'vuex'

export const VIEW_ALL = 'view_all'
export const VIEW_OWN = 'view_own'

export default {
  name: 'EventListOwn',
  components: {
    AppBar,
    TabBar,
    SpinnerLoader,
    GroupItem
  },
  data() {
    return {
      VIEW_ALL: VIEW_ALL,
      VIEW_OWN: VIEW_OWN
    }
  },
  computed: {
    ...mapState({
      allGroups: (state) => state.group.allGroups,
      groupsJoined: (state) => state.group.groupsJoined,
      joinedGroup: (state) => state.group.joinedGroup,
      createdGroup: (state) => state.group.createdGroup,
      loading: (state) => state.group.loading
    })
  },
  created() {
    this.$store.dispatch(GROUP_GET_ALL, { limit: 0 })
    this.$store.dispatch(GROUP_GET_JOINED, { limit: 0 })
    if (this.joinedGroup || this.createdGroup) {
      this.$store.dispatch(GROUP_RESET_STATE)
    }
  },
  methods: {
    routeToCreateGroup: function () {
      this.$router.push('/groups/new')
    },
    routeToAllGroups: function (view) {
      this.$router.push(`/groups/all?view=${view}`)
    },
    routeToGroupDetailPage: function (groupId) {
      this.$router.push(`/groups/${groupId}`)
    },
    routeToSearchGroup: function () {
      this.$router.push('/groups/search')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables';
.title-with-button {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}
.page-title {
  flex: 1;
  margin: 1rem 0;
}
.btn-float {
  width: 130px;
}
.noGroups {
  margin: 1rem 0;
}
.content-part {
  margin-top: 2rem;
}
</style>
