<template>
  <div class="has-tab-bar">
    <AppBar :title="group && group.name" :show-back-button="true" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <h1 class="page-title mt-4 mb-3">Alle groepsleden</h1>
      <template
        v-if="
          group &&
          group.members &&
          group.members.some((member) => member.status === 'accepted')
        "
      >
        <GroupListItem
          v-for="member in group.members.filter(
            (member) => member.status === 'accepted'
          )"
          :id="member.id"
          :key="member.id"
          :name="`${member.name}${
            isOwner(member.participantId) ? ' (beheerder)' : ''
          }`"
          :first-name="member.firstName"
          :last-name="member.lastName"
          :image-url="member.avatarUrl"
          :show-arrow="false"
          :show-pointer="false"
          class="mb-2"
        />
      </template>
      <template v-else>Geen leden gevonden</template>
      <template
        v-if="
          group &&
          group.members &&
          group.members.some((member) => member.status === 'pending') &&
          participantIsOwner
        "
      >
        <h1 class="page-title mt-4 mb-3">Leden in afwachting</h1>
        <GroupListItem
          v-for="member in group.members.filter(
            (member) => member.status === 'pending'
          )"
          :id="member.id"
          :key="member.id"
          :name="`${member.name}`"
          :image-url="member.avatarUrl"
          :show-arrow="false"
          :show-pointer="false"
          button-text="voeg toe"
          second-button-text="weiger"
          @button-action="addMember"
          @second-button-action="rejectMember"
        />
      </template>
    </div>
    <TabBar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapGetters, mapState } from 'vuex'
import { GROUP_GET_DETAILS, GROUP_UPDATE_MEMBER } from '@/store/actions/group'
import GroupListItem from '@/components/GroupListItem'

export default {
  name: 'GroupsMembers',
  components: { AppBar, SpinnerLoader, TabBar, GroupListItem },
  data() {
    return {
      groupId: this.$route.params.id,
      participantIsOwner: false
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      loading: (state) => state.group.loading,
      group: (state) => state.group.groupDetails,
      memberUpdated: (state) => state.group.memberUpdated
    })
  },
  watch: {
    memberUpdated(value) {
      if (value) {
        this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
      }
    },
    group(group) {
      this.participantIsOwner = parseInt(this.authId) === group.ownerId
    }
  },
  created: function () {
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
  },
  methods: {
    isOwner: function (participantId) {
      return participantId === this.group.ownerId
    },
    addMember(id) {
      this.$store.dispatch(GROUP_UPDATE_MEMBER, {
        groupId: this.groupId,
        participantId: id,
        status: 'accepted'
      })
    },
    rejectMember(id) {
      this.$store.dispatch(GROUP_UPDATE_MEMBER, {
        groupId: this.groupId,
        participantId: id,
        status: 'rejected'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
