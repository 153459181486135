<template>
  <div
    class="group-item"
    :class="{
      'group-item-block': view === 'blocks',
      'group-item-list': view === 'list'
    }"
  >
    <div class="group-image-wrapper">
      <div
        v-if="imageUrl"
        class="image-background"
        :style="{
          'background-image': `url(${imageUrl})`
        }"
      />
    </div>
    <div class="group-info">
      <div class="group-title">
        {{ name }}
      </div>
      <div v-if="view === 'list'">
        {{ city }}
      </div>
      <div v-if="view === 'list'">
        {{ $tc('member', memberCount) }}
      </div>
    </div>
    <div v-if="view === 'list'" class="arrow">
      <i class="fal fa-angle-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupItem',
  props: {
    view: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    city: {
      type: String,
      required: false,
      default: ''
    },
    memberCount: {
      type: Number,
      required: false,
      default: 0
    },
    imageUrl: {
      type: String,
      required: false,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';

.group-item {
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  display: flex;
  cursor: pointer;
}
.group-item-block {
  flex-direction: column;
  max-width: 104px;
}
.group-item-list {
  flex-direction: row;
  &:hover {
    background-color: $greyLight;
    .arrow {
      color: $ipGreen;
    }
  }
}
.group-image-wrapper {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 5px;
  background-color: $ipGreyLighter;
  margin-right: 1rem;
}
.group-item-block .group-image-wrapper {
  width: 88px;
  height: 88px;
}
.image-background {
  border-radius: 5px;
}
.group-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.group-title {
  font-weight: 600;
}
.arrow {
  font-size: 2rem;
  display: flex;
  align-items: center;
}
</style>
