<template>
  <div class="has-tab-bar">
    <AppBar
      :title="team ? team.name : 'Team details'"
      :show-back-button="true"
    />
    <SpinnerLoader v-if="loading" />
    <template v-else-if="team">
      <div class="container">
        <div v-if="team.imageUrl" class="team-logo">
          <div
            class="image-background"
            :style="{
              'background-image': `url(${team.imageUrl})`
            }"
          />
        </div>
        <h1 class="page-title mt-4 mb-5">
          {{ team.name }}
        </h1>
        <h2 class="page-title mt-4 mb-5">Teamleden</h2>
        <template v-if="teamMembers">
          <GroupListItem
            v-for="member in teamMembers"
            :id="member.id"
            :key="member.id"
            :name="member.name"
            :show-pointer="false"
            :show-arrow="false"
            :image-url="member.avatarUrl"
          />
        </template>
        <template v-if="isGroupOwner || isTeamOwner || isGroupLeader">
          <a
            href="#"
            class="btn btn-green btn-block btn-round mt-5"
            @click.prevent="routeToEditTeam(team.id)"
          >
            beheer dit team
          </a>
        </template>
      </div>
    </template>
    <template v-else>Team not found</template>
    <TabBar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import GroupListItem from '@/components/GroupListItem'
import { mapState, mapGetters } from 'vuex'
import { TEAM_GET_DETAILS, TEAM_GET_MEMBERS } from '@/store/actions/team'
import { GROUP_GET_DETAILS } from '@/store/actions/group'

export default {
  name: 'GroupsTeamDetailPage',
  components: { AppBar, SpinnerLoader, TabBar, GroupListItem },
  data() {
    return {
      groupId: this.$route.params.id,
      teamId: this.$route.params.teamId,
      team: null
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      loading: (state) =>
        state.group.loading || state.league.loading || state.team.loading,
      teamDetails: (state) => state.team.teamDetails,
      teamMembers: (state) => state.team.teamMembers,
      group: (state) => state.group.groupDetails
    }),
    isGroupLeader: function () {
      return this.group.admins.includes(parseInt(this.authId))
    },
    isGroupOwner: function () {
      return this.group.ownerId === parseInt(this.authId)
    },
    isTeamOwner: function () {
      return this.team.ownerId === parseInt(this.authId)
    }
  },
  watch: {
    teamDetails() {
      this.team = { ...this.teamDetails }
      this.$store.dispatch(TEAM_GET_MEMBERS, {
        lineupId: this.team.activeLineupId
      })
    }
  },
  created: function () {
    this.$store.dispatch(TEAM_GET_DETAILS, this.teamId)
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
  },
  mounted: function () {},
  methods: {
    routeToEditTeam: function () {
      this.$router.push(`/groups/${this.groupId}/team/${this.teamId}/edit`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.team-logo {
  position: relative;
  margin: 2rem auto;
  width: 200px;
  height: 200px;
  border-radius: 125px;
  border: 4px solid $ipGreen;
}
.image-background {
  border-radius: 125px;
}
</style>
