<template>
  <div class="has-tab-bar">
    <AppBar title="Beheer competitie" :show-back-button="true" />
    <SpinnerLoader v-if="loading" />
    <template v-else-if="league && (isOwner || isLeader)">
      <div class="container">
        <h1 class="page-title mt-4 mb-5">
          {{ league.name }}
        </h1>
        <div class="mt-5">
          <a
            href="#"
            class="btn btn-white-green-green btn-block btn-round"
            @click.prevent="routeToEditLeague"
          >
            competitie instellingen
          </a>
          <a
            href="#"
            class="btn btn-white-green-green btn-block btn-round"
            @click.prevent="routeToManageMatches"
          >
            wedstrijden en uitslagen
          </a>
          <a
            href="#"
            class="btn btn-outline-danger btn-block btn-round"
            @click.prevent="deleteLeague"
          >
            verwijder competitie
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div>Oeps, deze competitie bestaat niet</div>
    </template>
    <TabBar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState, mapGetters } from 'vuex'
import { LEAGUE_GET_DETAILS } from '@/store/actions/league'
import { GROUP_GET_DETAILS } from '@/store/actions/group'

export default {
  name: 'GroupsAdminLeague',
  components: { AppBar, SpinnerLoader, TabBar },
  data() {
    return {
      groupId: this.$route.params.id,
      leagueId: this.$route.params.leagueId
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      loading: (state) => state.league.loading || state.group.loading,
      league: (state) => state.league.leagueDetails,
      group: (state) => state.group.groupDetails
    }),
    isOwner: function () {
      return this.league && this.league.ownerId === parseInt(this.authId)
    },
    isLeader: function () {
      return this.group && this.group.admins.includes(parseInt(this.authId))
    }
  },
  created: function () {
    this.$store.dispatch(LEAGUE_GET_DETAILS, this.leagueId)
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
  },
  mounted: function () {},
  methods: {
    routeToEditLeague: function () {
      this.$router.push(`${this.leagueId}/edit`)
    },
    routeToManageMatches: function () {
      this.$router.push(`${this.leagueId}/matches`)
    },
    deleteLeague: function () {
      // delete league
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
