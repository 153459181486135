<template>
  <div>
    <AppBar
      title=""
      :show-back-button="true"
      :show-profile-button="false"
      :back-button-path="`/groups/${groupId}`"
    ></AppBar>
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div v-else class="container">
      <h1>{{ $t('creditcard.overview') }}</h1>
      <template v-if="creditcards.length">
        <card-item
          v-for="card in creditcards"
          :key="card.id"
          :card="card"
          :show-buy-button="false"
          @click-card="gotoCard(card.id)"
        />
      </template>
      <template v-else>
        <div>
          Je hebt nog geen strippenkaarten aangemaakt voor deze groep. Klik op
          de knop hieronder om een nieuwe strippenkaart aan te maken.
        </div>
      </template>

      <button class="button button-primary w-full mt-8" @click="createNewCard">
        Nieuwe strippenkaart
      </button>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import CardItem from '@/components/CardItem'
import { mapState } from 'vuex'
import config from '@/utils/config'
import {
  CREDITCARD_GET_GROUP,
  CREDITCARD_NEW_UPDATE
} from '../../../store/actions/creditcard'
import { CREDITCARD_GROUP_EVENTS } from '@/utils/constants'

export default {
  name: 'GroupsCreditcardOverview',
  components: {
    AppBar,
    SpinnerLoader,
    CardItem
  },
  beforeRouteEnter(to, from, next) {
    if (!config.featureFlags.creditcards) {
      next({ name: 'GroupsDetailPage', params: { id: to.params.id } })
    } else {
      next()
    }
  },
  data() {
    return {
      groupId: this.$route.params.id,
      title: null,
      CREDITCARD_GROUP_EVENTS: CREDITCARD_GROUP_EVENTS
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.creditcard.loading,
      creditcards: (state) => state.creditcard.groupCards
    })
  },
  watch: {},
  created: function () {
    this.$store.dispatch(CREDITCARD_GET_GROUP, { groupId: this.groupId })
  },
  methods: {
    createNewCard() {
      // set type to group card?
      const newCard = {
        type: CREDITCARD_GROUP_EVENTS,
        details: { groupId: parseInt(this.groupId) },
        source: 'group'
      }
      this.$store.commit(CREDITCARD_NEW_UPDATE, newCard)
      this.$router.push(`/cards/new`)
    },
    gotoCard(cardId) {
      this.$router.push(`/cards/${cardId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.full-height {
  height: calc(100vh - 10rem);
}
</style>
