<template>
  <Transition name="slide">
    <div
      v-if="show"
      class="slide-mask"
      :style="cssVars"
      @click.self="$emit('close')"
    >
      <div :class="!isMobile ? 'container' : ''">
        <div
          :class="
            isMobile
              ? 'context-menu-container-bottom'
              : 'context-menu-container-top'
          "
        >
          <div class="px-6">
            <!-- <div v-if="!isMobile" class="context-arrow" /> -->
            <a
              v-for="item in items"
              :key="item.key"
              href="#"
              class="py-4 px-0 block no-underline hover:text-green-600"
              @click.prevent="
                preventScrolling(false)
                $emit('close')
                $emit('click', item.clickAction)
              "
            >
              <div class="flex items-center">
                <IconBase class="mr-4" :icon="item.icon" />
                <span>{{ item.text }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import isMobile from '@/utils/isMobile'
import IconBase from '@/components/IconBase.vue'

export default {
  name: 'ContextMenu',
  components: { IconBase },
  props: {
    items: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isMobile: isMobile()
    }
  },
  computed: {
    cssVars() {
      return {
        '--menu-items': this.items.length
      }
    }
  },
  watch: {
    show: function (modalOpen) {
      this.preventScrolling(modalOpen)
    }
  },
  created: function () {},
  methods: {
    preventScrolling(modalOpen) {
      if (modalOpen) {
        // document.body.style.position = 'fixed'
        // document.body.style['padding-right'] = '15px'
        // document.body.style.top = `-${window.scrollY}px`
      } else {
        // const scrollY = document.body.style.top
        // document.body.style.position = ''
        // document.body.style.top = ''
        // document.body.style['padding-right'] = ''
        // window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.slide-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: table;
  transition: all 0.2s ease;
}
.context-arrow {
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
  position: absolute;
  top: -7px;
  &:before {
    content: '';
    position: absolute;
    left: 194px;
    -webkit-transform: translateZ(-1px);
    transform: translateZ(-1px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
}
.context-menu-container-bottom {
  display: table-cell;
  vertical-align: top;
  background: #fff;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: calc((var(--menu-items) * 3.5rem) + 1.5rem);
  transition: all 0.2s ease;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.context-menu-container-top {
  @apply shadow-lg right-1 bg-white absolute mt-10 w-auto my-0 mx-auto rounded-lg transition-all ease-in-out duration-200;
  height: calc(var(--menu-items) * 3.5rem);
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  .context-menu-container-bottom {
    transform: translateY(calc(var(--menu-items) * 3.5rem));
  }
  .context-menu-container-top {
    transform: -1rem;
  }
}
</style>
