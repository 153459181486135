<template>
  <div
    class="rounded-full bg-gray-300 text-white text-center flex items-center font-semibold tracking-wider mr-4"
    :class="[
      size === 10 ? 'h-10 w-10' : `w-${size} h-${size}`,
      size <= 10 ? 'text-xl' : size <= 10 ? 'text-2xl' : 'text-3xl'
    ]"
  >
    <div class="flex-1">{{ initials }}</div>
  </div>
</template>

<script>
export default {
  name: 'InitialsAvatar',
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 12
    }
  },
  computed: {
    initials: function () {
      return `${this.firstName.charAt(0).toUpperCase()}${this.lastName
        .charAt(0)
        .toUpperCase()}`
    }
  }
}
</script>

<style></style>
