<template>
  <div class="has-tab-bar">
    <AppBar :title="group ? group.name : ''" :show-back-button="true" />
    <SpinnerLoader v-if="loading || !group" />
    <div v-else class="container">
      <!-- manage teams -->
      <template v-if="view === 'teams'">
        <template v-if="selected">Edit one team</template>
        <template v-else>
          <h1 class="page-title mt-4 mb-5">Beheer teams</h1>
          <h2 class="page-title">Huidige teams</h2>
          <GroupListItem
            v-for="team in group.teams"
            :id="team.id"
            :key="team.id"
            :name="team.name"
            :image-url="team.image"
            @click.native="routeToTeamDetails(team.id)"
          />
          <a
            href="#"
            class="btn btn-white-green-green btn-block btn-round"
            @click.prevent="routeToCreateTeam"
          >
            nieuw team
          </a>
        </template>
      </template>

      <!-- manage leagues -->
      <template v-else-if="view === 'leagues'">
        <template v-if="selected">Edit one league</template>
        <template v-else>
          <h1 class="page-title mt-4 mb-5">Beheer competities</h1>
          <p>
            Hier kun je competities aanmaken en beheren en groepsleden aanwijzen
            als leader
          </p>
          <div class="mt-5">
            <h2 class="page-title">Huidige competities</h2>
            <GroupListItem
              v-for="league in group.leagues"
              :id="league.id"
              :key="league.id"
              :name="league.name"
              :image-url="league.image"
              @click.native="routeToLeagueDetails(league.id)"
            />
            <a
              href="#"
              class="btn btn-white-green-green btn-block btn-round"
              @click.prevent="routeToCreateLeague"
            >
              nieuwe competitie
            </a>
          </div>
          <div class="mt-5">
            <h2 class="page-title">Leaders</h2>
            <template v-if="currentAdmins.length">
              <p>
                Leaders kunnen competities, wedstrijden, uitslagen en standen
                invoeren en beheren
              </p>
              <GroupListItem
                v-for="admin in currentAdmins"
                :id="admin.id"
                :key="admin.id"
                :image-url="admin.avatarUrl"
                :name="admin.name"
                button-text="Verwijder"
                :show-arrow="false"
                :show-pointer="false"
                @button-action="removeAdmin"
              />
            </template>
            <template v-else>
              <p>
                Deze groep heeft nog geen leaders. Zoek op naam om een of
                meerdere leaders toe te voegen
              </p>
            </template>
            <div class="form-group">
              <input
                v-model="searchTerm"
                type="text"
                class="form-control"
                placeholder="Zoek op gebruikersnaam"
                @input="search"
              />
            </div>
            <div v-if="searchResults" class="search-results">
              <GroupListItem
                v-for="participant in searchResults"
                :id="participant.id"
                :key="participant.id"
                :image-url="participant.avatarUrl"
                :name="participant.name"
                button-text="Voeg toe"
                :show-arrow="false"
                :show-pointer="false"
                :button-disabled="
                  currentAdmins &&
                  currentAdmins
                    .map((admin) => admin.id)
                    .includes(participant.id)
                "
                button-text-disabled="Toegevoegd"
                @button-action="addAdmin"
              />
              <a
                v-if="groupUpdated"
                href="#"
                class="btn btn-green btn-block btn-round"
                @click.prevent="saveAdmins"
              >
                opslaan
              </a>
            </div>
          </div>
        </template>
      </template>

      <!-- admin main screen -->
      <template v-else>
        <template v-if="participantIsOwner || participantIsAdmin">
          <h1 class="page-title mt-4 mb-5">Beheer je groep</h1>
          <div class="mt-5">
            <a
              href="#"
              class="btn btn-white-green-green btn-block btn-round"
              @click.prevent="routeToEditGroup"
            >
              groepsinstellingen
            </a>
            <a
              v-if="teamAndLeaguesEnabled"
              href="#"
              class="btn btn-white-green-green btn-block btn-round"
              @click.prevent="routeToManageTeams"
            >
              beheer teams
            </a>
            <a
              v-if="teamAndLeaguesEnabled"
              href="#"
              class="btn btn-white-green-green btn-block btn-round"
              @click.prevent="routeToManageLeagues"
            >
              beheer competities
            </a>
            <a
              href="#"
              class="btn btn-outline-danger btn-block btn-round"
              @click.prevent="showDeleteModal = true"
            >
              verwijder groep
            </a>
            <modal :show="showDeleteModal" @close="showDeleteModal = false">
              <template v-slot:header>
                {{ group.name }} wordt verwijderd
              </template>
              <template>
                <p>Weet je zeker dat je {{ group.name }} wilt verwijderen?</p>
                <div class="alert alert-danger">
                  Alle teams, competities, uitslagen en rankings gaan verloren.
                  Dit kan niet ongedaan worden gemaakt!
                </div>
              </template>
              <template v-slot:footer>
                <a
                  href="#"
                  class="btn btn-danger btn-block btn-round"
                  @click.prevent="deleteGroup"
                >
                  JA, verwijderen
                </a>
                <a
                  href="#"
                  class="btn btn-white-green-green btn-block btn-round"
                  @click.prevent="showDeleteModal = false"
                >
                  NEE, annuleren
                </a>
              </template>
            </modal>
            <modal :show="showDeletedIsDone" @close="routeToGroupOverview">
              <template v-slot:header>{{ group.name }} is verwijderd!</template>
              <template>
                Weet je zeker dat je {{ group.name }} wilt verwijderen?
              </template>
              <template v-slot:footer>
                <a
                  href="#"
                  class="btn btn-white-green-green btn-block btn-round"
                  @click.prevent="routeToGroups"
                >
                  Terug naar groepen
                </a>
              </template>
            </modal>
          </div>
        </template>
        <template v-else>
          Je hebt geen rechten om deze groep te wijzigen
        </template>
      </template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import config from '@/utils/config'
import _ from 'lodash'
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import GroupListItem from '@/components/GroupListItem'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import {
  GROUP_GET_DETAILS,
  GROUP_UPDATE,
  GROUP_DELETE,
  GROUP_RESET_STATE
} from '@/store/actions/group'
import {
  PARTICIPANT_SEARCH_CLEAR,
  PARTICIPANT_SEARCH,
  PARTICIPANT_GET_BY_IDS
} from '@/store/actions/participant'

export default {
  name: 'GroupsAdmin',
  components: { AppBar, GroupListItem, SpinnerLoader, TabBar, Modal },
  data() {
    return {
      groupId: this.$route.params.id,
      view: this.$route.query.view,
      selected: this.$route.query.selected,
      currentAdmins: [],
      originalAdmins: [],
      groupUpdated: false,
      searchTerm: null,
      showDeleteModal: false,
      showDeletedIsDone: false,
      teamAndLeaguesEnabled: config.featureFlags.teamsAndLeagues
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      loading: (state) => state.group.loading,
      group: (state) => state.group.groupDetails,
      searchResults: (state) => state.participant.searchResults,
      participantsById: (state) => state.participant.participantsById,
      groupIsDeleted: (state) => state.group.groupIsDeleted
    }),
    participantIsOwner: function () {
      return this.group && this.group.ownerId === parseInt(this.authId)
    },
    participantIsAdmin: function () {
      return this.group && this.group.admins.includes(parseInt(this.authId))
    }
  },
  watch: {
    group(group) {
      if (group.admins.length) {
        this.$store.dispatch(PARTICIPANT_GET_BY_IDS, { ids: group.admins })
      }

      this.originalAdmins = [...group.admins]
      this.currentAdmins = [...group.admins]
    },
    participantsById(admins) {
      this.originalAdmins = [...admins]
      this.currentAdmins = [...admins]
    },
    $route(from) {
      this.view = from.query.view
      this.selected = from.query.selected
    },
    groupIsDeleted(value) {
      if (value) {
        this.showDeletedIsDone = true
        this.$commit(GROUP_RESET_STATE)
      }
    }
  },
  created: function () {
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    this.$store.commit(PARTICIPANT_SEARCH_CLEAR)
  },
  methods: {
    routeToGroups: function () {
      this.$router.push('/groups')
    },
    routeToEditGroup: function () {
      this.$router.push(`${this.groupId}/edit`)
    },
    routeToManageTeams: function () {
      this.$router.push('?view=teams')
    },
    routeToManageLeagues: function () {
      this.$router.push('?view=leagues')
    },
    routeToLeagueDetails: function (leagueId) {
      this.$router.push(`${this.groupId}/league/${leagueId}`)
    },
    routeToTeamDetails: function (teamId) {
      this.$router.push(`${this.groupId}/team/${teamId}/edit`)
    },
    routeToCreateTeam: function () {
      this.$router.push(`${this.groupId}/team/new`)
    },
    routeToCreateLeague: function () {
      this.$router.push(`${this.groupId}/league/new`)
    },
    addAdmin(id) {
      const participant = this.searchResults.find((item) => item.id === id)
      this.currentAdmins.push(participant)
      this.currentAdmins.sort((a, b) => a - b)
      this.groupUpdated = !_.isEqual(this.currentAdmins, this.originalAdmins)
    },
    removeAdmin(id) {
      this.currentAdmins = this.currentAdmins.filter((admin) => admin.id !== id)
      this.currentAdmins.sort((a, b) => a - b)
      this.groupUpdated = !_.isEqual(this.currentAdmins, this.originalAdmins)
    },
    saveAdmins() {
      this.$store.dispatch(GROUP_UPDATE, {
        groupId: this.groupId,
        group: {
          admins: this.currentAdmins.map((admin) => admin.id)
        }
      })
      this.searchTerm = null
      this.groupUpdated = false
      this.$store.commit(PARTICIPANT_SEARCH_CLEAR)
    },
    deleteGroup() {
      this.$store.dispatch(GROUP_DELETE, this.groupId)
      this.showDeleteModal = false
    },
    search: _.debounce(function (e) {
      if (e.target.value.length > 2) {
        this.$store.dispatch(PARTICIPANT_SEARCH, {
          query: e.target.value,
          groupId: this.groupId
        })
      } else {
        this.$store.commit(PARTICIPANT_SEARCH_CLEAR)
      }
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
