<template>
  <div class="item" :class="showPointer && 'item-pointer'">
    <div v-if="textLeft" class="left">
      {{ textLeft }}
    </div>
    <div v-if="showImage" class="image-wrapper">
      <div
        v-if="imageUrl"
        class="image-background"
        :style="{
          'background-image': `url(${imageUrl})`
        }"
      />
      <initials-avatar
        v-else
        :first-name="firstName"
        :last-name="lastName"
        :size="16"
      />
    </div>
    <div class="info">
      <div class="title">
        {{ name }}
      </div>
      <div class="extra-text">
        {{ extraText }}
      </div>
    </div>
    <div v-if="textRight" class="right">
      {{ textRight }}
    </div>
    <div v-if="buttonText" class="my-auto">
      <a
        v-if="!buttonDisabled"
        href="#"
        class="btn btn-small btn-green btn-round"
        @click.prevent="onButtonClick(id)"
      >
        {{ buttonText }}
      </a>
      <div v-else class="btn btn-small btn-disabled btn-round">
        {{ buttonTextDisabled || buttonText }}
      </div>
    </div>
    <div v-if="secondButtonText" class="my-auto">
      <a
        href="#"
        class="btn btn-small btn-outline-danger btn-round ml-2"
        @click.prevent="onSecondButtonClick(id)"
      >
        {{ secondButtonText }}
      </a>
    </div>
    <div v-if="showArrow" class="arrow">
      <i class="fal fa-angle-right" />
    </div>
  </div>
</template>

<script>
import InitialsAvatar from './InitialsAvatar.vue'
export default {
  name: 'GroupListItem',
  components: { InitialsAvatar },
  props: {
    name: {
      type: String,
      required: true
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      required: true
    },
    imageUrl: {
      type: String,
      required: false,
      default: ''
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonText: {
      type: String,
      required: false,
      default: ''
    },
    secondButtonText: {
      type: String,
      required: false,
      default: ''
    },
    buttonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonTextDisabled: {
      type: String,
      required: false,
      default: ''
    },
    showPointer: {
      type: Boolean,
      required: false,
      default: true
    },
    extraText: {
      type: String,
      required: false,
      default: ''
    },
    textRight: {
      type: String,
      required: false,
      default: ''
    },
    textLeft: {
      type: String,
      required: false,
      default: ''
    },
    showImage: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {}
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  methods: {
    onButtonClick: function (id) {
      this.$emit('button-action', id)
    },
    onSecondButtonClick: function (id) {
      this.$emit('second-button-action', id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.item {
  display: flex;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
}
.item-pointer {
  cursor: pointer;
  &:hover {
    background-color: $greyLight;
    .arrow {
      color: $ipGreen;
    }
  }
}
.image-wrapper {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: $ipGreyLighter;
  margin-right: 1rem;
}
.image-background {
  border-radius: 32px;
}
.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: auto 0;
}
.title {
  font-weight: 600;
  font-size: 1.125rem;
  margin: auto 0;
}
.arrow {
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.btn-disabled {
  background-color: $ipGreyLighter;
  color: white;
  border: 0;
  &:hover,
  &:focus,
  &:active {
    border: 0;
    background-color: $ipGreyLighter;
    cursor: default;
  }
}
.extra-text {
  width: 100%;
}
.left {
  font-size: 1.125rem;
  margin: auto 0;
  width: 2rem;
}
.right {
  font-weight: bold;
  font-size: 1.125rem;
  margin: auto 0;
}
</style>
