<template>
  <modal :show="showModal" @close="closeModal">
    <template v-slot:header>
      Eindstand opslaan
      <p class="mb-2 mt-3">
        {{ title }}
      </p>
      <p>{{ date }}</p>
    </template>
    <template>
      <p v-if="foundResult" class="alert alert-info">
        Er is al een eindstand gevonden voor dit event. Voer een nieuwe stand in
        als je deze wilt overschrijven
      </p>
      <form class="mt-4" @submit.prevent="saveResult">
        <div
          v-for="(error, idx) in errors"
          :key="idx"
          class="alert alert-danger"
        >
          {{ error }}
        </div>
        <div v-for="n in teams.length" :key="n">
          <div class="form-group">
            <FormSelect
              v-model="result[n]"
              :items="teams"
              :empty-text="`De nummer ${n}`"
              :border-size="2"
              :required="true"
            />
          </div>
        </div>
        <div class="my-4">
          <button type="submit" class="btn btn-green btn-block btn-round">
            <span v-if="foundResult">overschrijven</span>
            <span v-else>opslaan</span>
          </button>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <a
        href="#"
        class="btn btn-white-green-green btn-block btn-round"
        @click.prevent="closeModal"
      >
        annuleren
      </a>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import FormSelect from '@/components/FormSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'SaveResultModal',
  components: { Modal, FormSelect },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    teams: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      result: {},
      errors: []
    }
  },
  computed: {
    ...mapGetters(['foundResult'])
  },
  beforeDestroy() {},
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    saveResult() {
      this.errors = []
      const values = Object.keys(this.result).map((rank) => this.result[rank])
      const unique = new Set(values).size === values.length
      if (!unique) {
        this.errors = [
          ...this.errors,
          'Kies voor elke klassering een ander team'
        ]
      } else {
        this.$emit('save-result', this.result)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
