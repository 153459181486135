<template>
  <transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div
          class="bg-white mx-auto max-w-lg rounded shadow-2xl transition duration-200 modal-container"
          :class="size === 'lg' ? 'mt-6' : 'mt-8'"
        >
          <div
            class="bg-green-600 text-white py-3 px-4 font-semibold text-center text-lg rounded-t flex"
          >
            <div class="flex-1"><slot name="header" /></div>
            <icon-base
              v-if="showCloseIcon"
              size="28"
              class="hover:cursor-pointer"
              @click.native="$emit('close')"
            >
              <icon-x />
            </icon-base>
          </div>

          <div
            class="overflow-y-auto p-8"
            :class="size === 'lg' ? 'max-h-60v' : 'max-h-60v'"
          >
            <slot>default body</slot>
          </div>

          <div class="modal-footer justify-center">
            <slot name="footer">
              <button
                class="button button-primary w-48"
                @click="$emit('close')"
              >
                sluiten
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconBase from '@/components/IconBase'
import IconX from '@/components/icons/IconX'

export default {
  name: 'Modal',
  components: {
    IconBase,
    IconX
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: all 0.3s ease;
}

.modal-container {
  width: 95vw;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-header {
  display: block;
  text-align: center;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter,
.modal-leave-to {
  opacity: 0;
  .modal-container {
    transform: translateY(-100%);
  }
}
</style>
