<template>
  <div class="has-tab-bar">
    <AppBar
      :title="leagueId ? 'Competitie aanpassen' : 'Nieuwe competitie maken'"
      :show-back-button="true"
    />
    <div class="container">
      <SpinnerLoader v-if="loading" />
      <template v-else-if="createdLeague">
        <h1 class="page-title mt-4">Gelukt!</h1>
        <p class="mt-5">
          Je hebt de competitie
          <strong>{{ createdLeague.name }}</strong>
          aangemaakt en kunt beginnen met het toevoegen van wedstrijden.
        </p>
        <div class="mt-5">
          <a
            href="#"
            class="btn btn-green btn-block btn-round"
            @click.prevent="routeToLeague(createdLeague.id)"
          >
            bekijk je competitie
          </a>
        </div>
      </template>
      <template v-else-if="updatedLeague">
        <h1 class="page-title mt-4">Gelukt!</h1>
        <p class="mt-5">
          Je hebt de competitie
          <strong>{{ updatedLeague.name }}</strong>
          bijgewerkt.
        </p>
        <div class="mt-5">
          <a
            href="#"
            class="btn btn-green btn-block btn-round"
            @click.prevent="routeToLeague(updatedLeague.id)"
          >
            bekijk je competitie
          </a>
        </div>
      </template>
      <template v-else-if="league">
        <GroupLeagueCreateOrEditForm
          :league="league"
          @submit-league="submitLeague"
          @remove-file="onFileRemove"
          @change-file="onFileChange"
        />
      </template>
      <template v-else>Fout: competitie niet gevonden!</template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import {
  LEAGUE_GET_DETAILS,
  LEAGUE_CREATE,
  LEAGUE_UPDATE,
  LEAGUE_RESET_STATE
} from '@/store/actions/league'
import GroupLeagueCreateOrEditForm from '@/components/GroupLeagueCreateOrEditForm'
import { getOrientation } from '@/utils/getOrientation'

export default {
  name: 'GroupsLeagueCreateOrEdit',
  components: { AppBar, SpinnerLoader, TabBar, GroupLeagueCreateOrEditForm },
  data() {
    return {
      groupId: this.$route.params.id,
      leagueId: this.$route.params.leagueId,
      imageOrientation: null,
      league: {
        default: () => ({
          image: null,
          imageUrl: null,
          name: null
        })
      }
    }
  },
  computed: {
    ...mapState({
      loading: (state) =>
        state.group.loading || state.league.loading || state.team.loading,
      leagueDetails: (state) => state.league.leagueDetails,
      createdLeague: (state) => state.league.createdLeague,
      updatedLeague: (state) => state.league.updatedLeague
    })
  },
  watch: {
    leagueDetails() {
      this.league = { ...this.leagueDetails }
    }
  },
  created: function () {
    this.$store.commit(LEAGUE_RESET_STATE)
    if (this.leagueId) {
      this.$store.dispatch(LEAGUE_GET_DETAILS, this.leagueId)
    }
  },
  methods: {
    routeToLeague: function (id) {
      this.$router.push(`${id}`)
    },
    submitLeague: function (league) {
      if (this.leagueId) {
        this.$store.dispatch(LEAGUE_UPDATE, {
          leagueId: this.leagueId,
          league
        })
      } else {
        this.$store.dispatch(LEAGUE_CREATE, {
          groupId: this.groupId,
          league
        })
      }
    },
    onFileChange: function (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.league.image = files[0]
      const vm = this
      getOrientation(files[0], function (orientation) {
        switch (orientation) {
          case 3:
            vm.imageOrientation = 'orientation-3'
            break
          case 6:
            vm.imageOrientation = 'orientation-6'
            break
          case 8:
            vm.imageOrientation = 'orientation-8'
            break
        }
      })
      // Set image url in view
      const reader = new FileReader()
      reader.onload = (e) => {
        vm.league.imageUrl = e.target.result
      }
      reader.readAsDataURL(files[0])
    },
    onFileRemove: function () {
      this.league.image = null
      this.league.imageUrl = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
