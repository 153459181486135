<template>
  <div class="has-tab-bar">
    <AppBar :title="group && group.name" :show-back-button="true" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <template v-if="group">
        <h1 class="page-title mt-4 mb-5">Alle teams</h1>
        <template v-if="group.teams">
          <GroupListItem
            v-for="team in group.teams"
            :id="team.id"
            :key="team.id"
            :name="team.name"
            :image-url="team.image"
            @click.native="routeToTeamDetailPage(team.id)"
          />
        </template>
      </template>
      <template v-else>Not found</template>
    </div>
    <TabBar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import { GROUP_GET_DETAILS } from '@/store/actions/group'
import GroupListItem from '@/components/GroupListItem'

export default {
  name: 'GroupsTeams',
  components: { AppBar, SpinnerLoader, TabBar, GroupListItem },
  data() {
    return {
      groupId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.group.loading,
      group: (state) => state.group.groupDetails
    })
  },
  watch: {},
  created: function () {
    if (!this.group) {
      this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    }
  },
  mounted: function () {},
  methods: {
    routeToTeamDetailPage: function (teamId) {
      this.$router.push(`/groups/${this.groupId}/team/${teamId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
