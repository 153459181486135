<template>
  <div class="has-tab-bar">
    <AppBar
      :title="activeTab === VIEW_ALL ? viewAllTitle : viewOwnTitle"
      :show-back-button="true"
      back-button-path="/groups"
    />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <TabNavigation :tabs="tabs" :active-tab="activeTab" @toggle="toggleNav" />
      <template
        v-if="groupsToShow && groupsToShow.data && groupsToShow.data.length"
      >
        <div class="d-flex flex-column">
          <GroupItem
            v-for="group in groupsToShow.data"
            :key="`${activeTab}_${group.id}`"
            view="list"
            :name="group.name"
            :city="group.city"
            :member-count="group.acceptedMembers"
            :image-url="group.imageUrl"
            @click.native="routeToGroupDetailPage(group.id)"
          />
        </div>
      </template>
    </div>
    <TabBar selected="groups" />
  </div>
</template>

<script>
import AppBar from '@/components//AppBar'
import GroupItem from '@/components/GroupItem'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import TabNavigation from '@/components/TabNavigation'
import { mapState } from 'vuex'
import { GROUP_GET_ALL, GROUP_GET_JOINED } from '@/store/actions/group'
import { VIEW_ALL, VIEW_OWN } from './GroupsOverview'

export default {
  name: 'GroupsAll',
  components: { AppBar, GroupItem, SpinnerLoader, TabBar, TabNavigation },
  data() {
    return {
      VIEW_ALL: VIEW_ALL,
      VIEW_OWN: VIEW_OWN,
      viewOwnTitle: 'Mijn groepen',
      viewAllTitle: 'Alle groepen',
      activeTab: this.$route.query.view,
      groupsToShow: []
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.group.loading,
      allGroups: (state) => state.group.allGroups,
      groupsJoined: (state) => state.group.groupsJoined
    }),
    tabs: function () {
      return [
        {
          key: VIEW_ALL,
          value: `${this.viewAllTitle} (${this.allGroups.data.length})`
        },
        {
          key: VIEW_OWN,
          value: `${this.viewOwnTitle} (${this.groupsJoined.data.length})`
        }
      ]
    }
  },
  watch: {
    $route(from) {
      this.activeTab = from.query.view
    },
    activeTab(value) {
      this.groupsToShow =
        value === VIEW_ALL ? this.allGroups : this.groupsJoined
    },
    allGroups(data) {
      if (this.activeTab === VIEW_ALL) {
        this.groupsToShow = data
      }
    },
    groupsJoined(data) {
      if (this.activeTab === VIEW_OWN) {
        this.groupsToShow = data
      }
    }
  },
  created: function () {
    this.$store.dispatch(GROUP_GET_ALL, {})
    this.$store.dispatch(GROUP_GET_JOINED, {})
  },
  mounted: function () {
    this.groupsToShow =
      this.activeTab === VIEW_ALL ? this.allGroups : this.groupsJoined
    // this.scroll()
  },
  methods: {
    routeToGroupDetailPage: function (groupId) {
      this.$router.push(`/groups/${groupId}`)
    },
    toggleNav: function (navItem) {
      this.$router.push({ query: { view: navItem } })
    }
    // scroll() {
    //   window.onscroll = () => {
    //     let bottomOfWindow =
    //       document.documentElement.scrollTop + window.innerHeight ===
    //       document.documentElement.offsetHeight

    //     if (bottomOfWindow) {
    //       this.$store.dispatch(GROUP_GET_ALL_MORE, { limit: 10, skip: 10 })
    //     }
    //   }
    // },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.page-title {
  margin: 2rem 0;
}
.tab-nav {
  display: flex;
  margin: 2rem 0;
}
.tab {
  width: 50%;
  padding: 1rem 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid $ipGreyLighter;
  color: $ipGreyLight;
}
.tab.active {
  font-weight: 600;
  color: $ipGreen;
  border-color: $ipGreen;
}
</style>
