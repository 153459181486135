<template>
  <div class="form-select">
    <select
      class="form-control"
      :class="{ 'form-control-subtle': variant === 'subtle' }"
      :style="{ border: borderSize + 'px solid ' + borderColor }"
      :required="required"
      :disabled="disabled"
      @change="$emit('input', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    >
      <option v-if="emptyText !== null" value="">
        {{ emptyText }}
      </option>
      <option
        v-for="item in items"
        :key="item.key"
        :value="item.key"
        :selected="item.key === value"
      >
        {{ item.value }}
      </option>
    </select>
    <div
      class="select-arrow-background"
      :style="{ backgroundColor: selectArrowBackgroundColor }"
    />
    <div class="select-arrow" :style="{ borderTopColor: selectArrowColor }" />
  </div>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    emptyText: {
      type: String,
      required: false,
      default: null
    },
    borderColor: {
      type: String,
      required: false,
      default: '#000'
    },
    borderSize: {
      type: Number,
      required: false,
      default: 1
    },
    selectArrowColor: {
      type: String,
      required: false,
      default: '#FFF'
    },
    selectArrowBackgroundColor: {
      type: String,
      required: false,
      default: '#009B6D'
    },
    variant: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';

.form-select {
  position: relative;
  display: inline-block;
  width: 100%;
}
.form-select select {
  display: inline-block;
  width: 100%;
  outline: 0;
  border: 1px solid $ipBlack;
  background: $ipWhite;
  color: $ipBlack;
  font-size: 1.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-select select::-ms-expand {
  display: none;
}

.form-select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select-arrow {
  position: absolute;
  top: 19px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 12px 10px 0px 10px;
  border-color: $ipWhite transparent transparent transparent;
}
.select-arrow-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: $ipGreen;
  pointer-events: none;
}
</style>
