<template>
  <datetime
    v-model="dateModel"
    :placeholder="placeholder"
    input-class="form-control rounded"
    class="theme-green neww"
    :min-datetime="minDateTime || new Date().toISOString()"
    :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
    @input="changeDate"
  />
</template>
<script>
export default {
  name: 'Datepicker',
  props: {
    date: {
      type: String,
      required: false,
      default: undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Datum'
    },
    minDateTime: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      dateModel: undefined
    }
  },
  created: function () {
    this.dateModel = this.date
  },
  methods: {
    changeDate: function (val) {
      if (val) {
        this.$emit('change-date', val)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.rounded {
  border-radius: 0.5rem !important;
  border: 1px solid #aaa;
  font-size: 0.875rem;
}
</style>
