<template>
  <form @submit.prevent="submitLeague">
    <div class="image-wrapper">
      <template v-if="league.imageUrl">
        <div
          v-if="league.imageUrl"
          class="image-background"
          :style="{
            'background-image': 'url(' + league.imageUrl + ')'
          }"
        />
        <div class="remove-image" @click="removeFile">X</div>
      </template>
      <template v-else>
        <div class="no-image">
          <input
            id="image"
            type="file"
            class="input-file"
            accept="image/*"
            @change="changeFile"
          />
          <label for="image" class="btn btn-transparent-grey">
            Kies een competitie afbeelding
          </label>
        </div>
      </template>
    </div>
    <div class="form-group">
      <input
        v-model="league.name"
        type="text"
        class="form-control"
        placeholder="Wat is de naam van je competitie?"
        required
      />
    </div>
    <div class="mt-5">
      <button type="submit" class="btn btn-green btn-block btn-round">
        opslaan
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'GroupCreateOrEditForm',
  props: {
    league: {
      type: Object,
      required: true,
      default: () => ({
        image: null,
        imageUrl: null,
        name: null
      })
    }
  },
  data: function () {
    return {
      groupId: this.$route.params.id,
      leagueId: this.$route.params.leagueId
    }
  },
  methods: {
    submitLeague() {
      this.$emit('submit-league', this.league)
    },
    changeFile() {
      this.$emit('change-file', event)
    },
    removeFile() {
      this.$emit('remove-file')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.image-wrapper {
  position: relative;
  max-width: 640px;
  height: 200px;
  background-color: $ipGreyLighter;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-content: space-around;
}
.no-image {
  margin: 0 auto;
}
.btn-transparent-grey {
  color: $ipWhite;
  border: 0;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + label {
  display: block;
}
.remove-image {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $ipGreen;
  cursor: pointer;
  text-align: center;
  color: white;
}
</style>
